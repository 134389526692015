import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { ModalServerContext } from ".";
import { CustomSpinner } from "../Common/Spinner";
import { ModalServerFooter } from "./ModalFooter";
import { StepBarModal } from "./StepBarModalAddNewServer";
import { StepOneAddNewServerComponent } from "./StepOneAddNewServer";
import { StepTwoAddNewServerComponent } from "./StepTwoAddNewServer";

export const ModalServer: React.FC = () => {
  const {
    isOpen,
    onCloseBigModal,
    serverId,
    step,
    isOpenConfirm,
    onCloseConfirm,
    reset,
    loadingUpdate,
  } = useContext(ModalServerContext);

  const colorSecondary = useColorModeValue("blue.800", "darkThemeGrey.100");
  const colorHeader = useColorModeValue("blue.100", "darkThemeGrey.600");
  const colorBody = useColorModeValue("white", "darkThemeGrey.700");

  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onCloseBigModal}
        closeOnOverlayClick={false}
        size="3xl"
        motionPreset="slideInRight"
      >
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent bg={colorBody}>
          <ModalHeader
            color={colorSecondary}
            backgroundColor={colorHeader}
            fontWeight={"semibold"}
            fontSize="lg"
            borderRadius={6}
          >
            {!serverId
              ? step === 0
                ? "Add new server"
                : "New server job"
              : step === 0
              ? "Update server"
              : "Update server's job"}
          </ModalHeader>
          <ModalCloseButton
            onClick={onCloseBigModal}
            border="2px"
            color={colorSecondary}
            borderColor={colorSecondary}
            m={2}
          />
          <>
            {serverId && loadingUpdate ? (
              <CustomSpinner />
            ) : (
              <ModalBody pb={6} pt={6} color={colorSecondary}>
                <StepBarModal />
                {step === 0 ? (
                  <StepOneAddNewServerComponent />
                ) : (
                  <StepTwoAddNewServerComponent />
                )}
                <ModalServerFooter />
              </ModalBody>
            )}{" "}
          </>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalCloseButton
          onClick={onCloseConfirm}
          margin={"5px 8px 0px 0px"}
          color="blue.800"
          borderWidth={2}
          borderRadius={5}
          borderColor={"blue.800"}
        />
        <ModalOverlay backdropFilter="blur(10px)" backdropBlur={"10px"} />

        <ModalContent>
          <ModalBody bg={colorBody}>
            <VStack>
              <Box
                fontSize={20}
                textAlign="left"
                w="100%"
                fontWeight={600}
                py={2}
              >
                Discard all changes?
              </Box>
              <Box fontSize={16} textAlign="left" w="100%" fontWeight={400}>
                We won't be able to save your data if you move away from this
                page.
              </Box>
              <Spacer />
              <HStack w="100%">
                <Spacer />
                <Box>
                  <Button tabIndex={2} onClick={onCloseConfirm}>
                    {" "}
                    Go back
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      reset();
                      onCloseConfirm();
                    }}
                    colorScheme="danger"
                    bg="danger.500"
                    tabIndex={1}
                    autoFocus
                  >
                    Exit
                  </Button>
                </Box>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
