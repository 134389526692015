import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LogsDataContext } from ".";
import { theme } from "../../theme";
import { CustomTabs } from "../ProjectsComponent/CustomTabs";
import { OnlineStatus } from "../ProjectsComponent/OnlineStatus/OnlineStatus";
import { TabsPage } from "./TabsPageComponent";

export const ServerLogs: React.FC = () => {
  const logsContext = useContext(LogsDataContext);
  const navigate = useNavigate();
  const origin = "/dashboard/projects";

  const handleNavigate = (projectId: string) => {
    navigate(origin + "/" + projectId + "/" + logsContext.serverId);
  };

  const tabsName = ["Last 24 hours", "Last week", "Last month"];
  const colorPrimary = useColorModeValue("blue.700", "blue.400");
  const colorText = useColorModeValue("neutralGrey", "darkThemeGrey.100");
  const colorDescription = useColorModeValue("blue.900", "darkThemeGrey.100");

  return logsContext.loading ? (
    <Flex h="60vh" justifyContent={"center"} alignItems={"center"}>
      <Spinner
        size="xl"
        color="blue.500"
        thickness="4px"
        speed="0.65s"
        emptyColor="blue.100"
      />
    </Flex>
  ) : (
    <Box height="100%" overflowY="auto">
      <HStack w={"100%"} px={6} py={3}>
        <Flex alignItems={"center"}>
          <Flex
            mr={4}
            alignItems={"center"}
            justifyContent={"center"}
            w={8}
            h={8}
            borderRadius="100%"
            bgColor={"blue.100"}
            cursor="pointer"
            onClick={() => {
              handleNavigate(logsContext.projectId as string);
            }}
          >
            <ArrowBackIcon
              w={5}
              h={5}
              cursor="pointer"
              stroke={"blue.700"}
              color="blue.700"
            />
          </Flex>
          <Box flex={1}>
            <OnlineStatus
              status={logsContext.projectAndServerDetails.projectStatus}
              type={5}
            />
          </Box>
          <Flex
            ml={4}
            alignItems="center"
            h="100%"
            fontWeight="bold"
            fontSize="3xl"
          >
            {logsContext.projectAndServerDetails.projectName}
          </Flex>
        </Flex>
      </HStack>

      <Box w={"100%"} px={6} ml={3}>
        <Text fontWeight={"bold"} color={colorDescription} mb={1}>
          Description
        </Text>
        <Text color={theme.colors.neutralGrey}>
          {logsContext.projectAndServerDetails.projectDescription}{" "}
        </Text>
      </Box>

      <Box height="60vh" width="100%" px={6} marginTop={5}>
        <Tabs
          // onChange={(index) => {
          //   logsContext.setTabIndex(0);
          // }}
          index={logsContext.serverTabIndex}
        >
          <TabList color={theme.colors.neutralGrey}>
            {logsContext.serversList.map((server, i) => {
              return (
                <CustomTabs
                  onClick={() => {
                    navigate(
                      `/dashboard/${logsContext.projectId}/${server.id}/logs`
                    );
                  }}
                  index={i}
                  title={server.name}
                  status={server.status}
                  key={server.id + i}
                  size={4}
                />
              );
            })}
          </TabList>
          {logsContext.loadingLogs ? (
            <Flex h="60vh" justifyContent={"center"} alignItems={"center"}>
              <Spinner
                size="xl"
                color="blue.500"
                thickness="4px"
                speed="0.65s"
                emptyColor="blue.100"
              />
            </Flex>
          ) : (
            <Box
              key={logsContext.projectAndServerDetails.serverId}
              mx={6}
              my={2}
            >
              <Spacer />
              <Tabs
                variant="unstyled"
                mt={5}
                onChange={(index) => logsContext.setTabIndex(index)}
                index={logsContext.tabIndex}
              >
                <TabList>
                  <Flex ml={3}>
                    <Flex direction={"column"}>
                      <Text fontSize={20} fontWeight="bold">
                        {logsContext.projectAndServerDetails.serverName}{" "}
                        server's logs
                      </Text>
                      <Text fontSize={15} fontWeight="600" color="neutralGrey">
                        {`${logsContext.projectAndServerDetails.ipAddress}${
                          logsContext.projectAndServerDetails.isProduction
                            ? " - Production"
                            : ""
                        }`}
                      </Text>
                    </Flex>
                  </Flex>
                  <Spacer />
                  {tabsName.map((tab, i) => {
                    return (
                      <Tab
                        tabIndex={i}
                        key={i}
                        color={colorText}
                        fontWeight="semibold"
                        _selected={{ color: colorPrimary, fontWeight: "bold" }}
                      >
                        {tab}
                      </Tab>
                    );
                  })}
                </TabList>
                <TabPanels>
                  <TabsPage />
                </TabPanels>
              </Tabs>
            </Box>
          )}
        </Tabs>
      </Box>
    </Box>
  );
};
