import {
  Box,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { MotionButton } from "../../Utils/MotionComponents";
import { ProjectInterface, ServerInterface } from "../Common/types";
import { FirstStep } from "./FirstStep";
import { SecondStep } from "./SecondStep";
import { StepBar } from "./StepBar";
import { ModalServiceInterface } from "./type";
import { useManageService } from "./useManageService";

interface ManageServiceProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  project: ProjectInterface;
  server: ServerInterface;
  serviceId?: string;
  onSave: (val: ModalServiceInterface) => void;
}

//this is the main modal component, used for create and edit service
export const ManageService: React.FC<ManageServiceProps> = ({
  isOpen,
  setIsOpen,
  project,
  server,
  serviceId,
  onSave,
}) => {
  const indexLogic: any = useManageService(
    isOpen,
    setIsOpen,
    onSave,
    server,
    serviceId
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={indexLogic.checkForChanges}
        scrollBehavior={"inside"}
        closeOnOverlayClick={false}
        isCentered
        size={"4xl"}
        motionPreset="slideInRight"
      >
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent>
          <ModalHeader
            borderRadius={"8px 8px 0px 0px"}
            backgroundColor={useColorModeValue("blue.100", "darkThemeGrey.600")}
            color={indexLogic.colorHeader}
          >
            {indexLogic.step === 0
              ? serviceId
                ? "Edit service"
                : "New service"
              : serviceId
              ? "Edit job"
              : "New service job"}
          </ModalHeader>
          <ModalCloseButton
            onClick={indexLogic.checkForChanges}
            margin={"5px 8px 0px 0px"}
            color={indexLogic.colorHeader}
            borderWidth={2}
            borderRadius={5}
            borderColor={indexLogic.colorHeader}
          />
          <ModalBody
            p={4}
            bg={useColorModeValue("white", "darkThemeGrey.700")}
            pr={8}
            pl={8}
          >
            <StepBar
              step={indexLogic.step}
              setStep={indexLogic.setStep}
              handleNextClick={indexLogic.handleFinalClick}
            />
            {indexLogic.loading ? (
              <Flex w="100%" h="100%" align="center" justify="center">
                <Spinner />
              </Flex>
            ) : indexLogic.step === 0 ? (
              <FirstStep
                mode={serviceId ? "edit" : "create"}
                project={project}
                server={server}
                values={indexLogic.values}
                setValues={indexLogic.setValues}
                errors={indexLogic.errors}
                setErrors={indexLogic.setErrors}
              />
            ) : (
              <SecondStep
                project={project}
                server={server}
                values={indexLogic.values}
                setValues={indexLogic.setValues}
                errors={indexLogic.errors}
                setErrors={indexLogic.setErrors}
              />
            )}
          </ModalBody>
          <ModalFooter bg={useColorModeValue("white", "darkThemeGrey.700")}>
            <Box>
              <MotionButton
                tabIndex={2}
                color="white"
                colorScheme={useColorModeValue("blue", "blue")}
                backgroundColor={useColorModeValue("blue.700", "blue.400")}
                w={"280px"}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", bounce: 0.4 }}
                onClick={indexLogic.handleFinalClick}
              >
                {indexLogic.step === 0 ? "Next" : "Save"}
              </MotionButton>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={indexLogic.isOpenConfirm}
        onClose={() => {
          indexLogic.setIsOpenConfirm(false);
        }}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalCloseButton
          onClick={() => {
            indexLogic.setIsOpenConfirm(false);
          }}
          margin={"5px 8px 0px 0px"}
          color="blue.800"
          borderWidth={2}
          borderRadius={5}
          borderColor={"blue.800"}
        />
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent>
          <ModalBody bg={useColorModeValue("white", "darkThemeGrey.700")}>
            <VStack>
              <Box
                fontSize={20}
                textAlign="left"
                w="100%"
                fontWeight={600}
                py={2}
              >
                Discard all changes?
              </Box>
              <Box fontSize={16} textAlign="left" w="100%" fontWeight={400}>
                We won't be able to save your data if you move away from this
                page.
              </Box>
              <Spacer />
              <HStack w="100%">
                <Spacer />
                <Box>
                  <MotionButton
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", bounce: 0.4 }}
                    tabIndex={2}
                    onClick={() => {
                      indexLogic.setIsOpenConfirm(false);
                    }}
                  >
                    {" "}
                    Go back
                  </MotionButton>
                </Box>
                <Box>
                  <MotionButton
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", bounce: 0.4 }}
                    onClick={() => {
                      indexLogic.setIsOpenConfirm(false);
                      indexLogic.closeModal();
                    }}
                    colorScheme="danger"
                    bg="danger.500"
                    tabIndex={1}
                    autoFocus
                  >
                    Exit
                  </MotionButton>
                </Box>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
