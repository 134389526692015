import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorContext, UserContext } from "../../App";
import { ReactComponent as ArrowIcon } from "../../Assets/ProjectsIcon/ArrowIcon.svg";
import { ReactComponent as UsersButtonIcon } from "../../Assets/Users/UsersButtonIcon.svg";
import { theme } from "../../theme";
import { ProjectInterface } from "../Common/types";
import { WDConfirmDelete } from "../Common/WDConfirmDelete";
import { WDModalServer } from "../CreateServerModal";
import { ModalServerInterface } from "../CreateServerModal/types";
import { apiClient, authorise } from "../utils/apiClient";

interface ProjectCardFooterProps {
  project: ProjectInterface;
  deleteProject: (id: string) => void;
  projects: ProjectInterface[];
  setProjects: (val: ProjectInterface[]) => void;
  serverId: string;
}
export const ProjectCardFooter: React.FC<ProjectCardFooterProps> = ({
  project,
  deleteProject,
  projects,
  setProjects,
  serverId,
}) => {
  const origin = "/dashboard";
  const userContext = useContext(UserContext);
  const errorContext = useContext(ErrorContext);
  const handleDelete = async (id: string, onClose: () => void) => {
    await apiClient
      .delete(`/api/private/projects/${id}`, authorise())
      .then(() => {
        deleteProject(id);
        onClose();
      })
      .catch((err) => {
        errorContext?.createError([...err.response.data]);
      });
  };

  const navigate = useNavigate();
  const handleNavigate = (name: string) => {
    navigate(origin + "/" + name);
  };

  const textColor = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.darkThemeGrey[100]}`
  );
  const bgColor = useColorModeValue("white", "darkThemeGrey.600");
  const onSuccess = (modalDataServer: ModalServerInterface) => {
    setProjects([
      ...projects.map((p) => {
        if (p.projectId === project.projectId) {
          return {
            ...p,
            servers: [
              ...p.servers,

              {
                ipAddress: modalDataServer.IPv4Address,
                serverId: modalDataServer.serverId,
                isEnabled: modalDataServer.isEnabled,
                status: modalDataServer.status,
                name: modalDataServer.serverName,
                isProduction: modalDataServer.isProduction,
                services: [],
                isConfigured: false,
                usages: [],
              },
            ],
          };
        }
        return p;
      }),
    ]);
  };

  return (
    <HStack
      w="100%"
      bg={useColorModeValue("blue.100", "darkThemeGrey.500")}
      position="absolute"
      bottom={0}
      borderRadius={8}
    >
      <Box h={16} p={4}>
        {project.projectRole === "Designer" ||
        userContext.user.roleName === "Admin" ? (
          <Menu>
            <MenuButton
              pb={2}
              as={IconButton}
              aria-label="Options"
              icon={<UsersButtonIcon stroke={textColor} />}
              variant="outline"
            />
            <MenuList bg={bgColor}>
              <WDConfirmDelete
                handleDelete={handleDelete}
                id={project.projectId}
              >
                <MenuItem h="100%" color="danger.400">
                  Delete Project
                </MenuItem>
              </WDConfirmDelete>

              <WDModalServer setProjects={onSuccess} currentProject={project} />
            </MenuList>
          </Menu>
        ) : null}
      </Box>

      <Spacer />
      <HStack
        fontWeight={600}
        fontSize={16}
        p={4}
        onClick={() => {
          if (serverId === undefined) {
            handleNavigate("projects/" + project.projectId + "/no-server"); //for future separate project page
          } else {
            handleNavigate("projects/" + project.projectId + "/" + serverId); //for future separate project page
          }
        }}
        cursor="pointer"
      >
        <Box color={textColor}>See all projects details</Box>
        <ArrowIcon stroke={textColor} />
      </HStack>
    </HStack>
  );
};
