import moment from "moment";
import { theme } from "../../theme";
import { SelectOptionsInterface } from "../Common/types";
import { ModalServiceInterface, ServiceErrorInterface } from "./type";

export const defaultServiceValues: ModalServiceInterface = {
  name: "",
  ipV4: "",
  port: "",
  type: {
    id: "",
    name: "",
  },
  serviceTypeField: [],
  enabled: true,
  startDate: moment(new Date()).toISOString(),
  reccuringTime: 1,
  minimumNotificationLevel: 5,
  timeout: 15,
  timeoutNotificationLevel: 20,
};

export const defaultServiceError: ServiceErrorInterface = {
  name: "",
  ipV4: "",
  port: "",
  type: "",
  serviceTypeField: [],
  enabled: "",
  startDate: "",
  reccuringTime: "",
  minimumNotificationLevel: "",
  timeout: "",
  timeoutNotificationLevel: "",
};

export const notificationOptions = [
  {
    value: "0",
    display: "Information",
    color: theme.colors.notificationsColor.green,
  },
  {
    value: "10",
    display: "Yellow Alert",
    color: theme.colors.notificationsColor.yellow,
  },
  {
    value: "20",
    display: "Red Alert",
    color: theme.colors.notificationsColor.red,
  },
  {
    value: "30",
    display: "Critical",
    color: theme.colors.notificationsColor.purple,
  },
];

export const optionsTypesService: SelectOptionsInterface[] = [
  { value: "0", display: "Generic Service", color: "darkThemeGrey.700" },
  { value: "10", display: "WebAPI", color: "darkThemeGrey.700" },
  { value: "20", display: "MySQL", color: "darkThemeGrey.700" },
  { value: "30", display: "MSSQL", color: "darkThemeGrey.700" },
  { value: "40", display: "Redis", color: "darkThemeGrey.700" },
  { value: "50", display: "Elastic Search", color: "darkThemeGrey.700" },
  { value: "60", display: "MariaDb", color: "darkThemeGrey.700" },
  { value: "70", display: "FTP", color: "darkThemeGrey.700" },
  { value: "80", display: "SFTP", color: "darkThemeGrey.700" },
  { value: "90", display: "MongoDb", color: "darkThemeGrey.700" },
  { value: "100", display: "DNS Resolve", color: "darkThemeGrey.700" },
];
