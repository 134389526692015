import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { ModalTypesInterface } from "./types";

interface StepBarProps {
  value: ModalTypesInterface;
  step: number;
  setStep: (val: number) => void;
  setErrorMessage: (val: { name: string; description: string }) => void;
  setModalData: (val: ModalTypesInterface) => void;
}

export const StepBar: React.FC<StepBarProps> = ({
  setErrorMessage,
  step,
  value,
  setStep,
  setModalData,
}) => {
  const colorButtonPrimary = useColorModeValue("blue.800", "blue.400");
  const colorButtonSecondary = useColorModeValue(
    "neutralGrey",
    "darkThemeGrey.100"
  );
  return (
    <Flex>
      <Box
        onClick={() => {
          setStep(0);
        }}
        width={16}
        backgroundColor={step === 0 ? colorButtonPrimary : colorButtonSecondary}
        height={2}
        borderRadius={5}
        style={{ cursor: "pointer" }}
      />
      <Box
        onClick={() => {
          if (
            value?.name === "" ||
            value?.description === "" ||
            value?.name.length > 100
          ) {
            setErrorMessage({
              name:
                value?.name === ""
                  ? "This field is required!"
                  : "" || value?.name.length > 100
                  ? "This field is too long."
                  : "",
              description:
                value?.description === "" ? "This field is required!" : "",
            });
          } else {
            setStep(1);
          }
        }}
        width={16}
        backgroundColor={step === 1 ? colorButtonPrimary : colorButtonSecondary}
        height={2}
        borderRadius={5}
        marginLeft={1}
        style={{ cursor: "pointer" }}
      />
    </Flex>
  );
};
