import { Box, Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { ResourceStatusEnum } from "../../Utils/defaultObjects";

interface TableResourceComponentProps {
  children: ReactNode;
  resourceStatus: number;
}

export const TableResourceComponent: React.FC<TableResourceComponentProps> = ({
  children,
  resourceStatus,
}) => {
  return (
    <Flex
      justifyContent="center"
      bg={
        ResourceStatusEnum.filter((status) => {
          return status.value === resourceStatus;
        })[0].color
      }
      color="white"
      h={7}
      w={9}
      alignItems="center"
      borderRadius={5}
      p={1}
    >
      <Box fontWeight={600} fontSize={12}>
        {children}
      </Box>
    </Flex>
  );
};
