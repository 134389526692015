import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Switch,
  useColorModeValue,
  useDisclosure,
  VStack,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../../../App";
import { requiredField } from "../../../Utils/ValidatorsForInput";
import { ProjectInterface } from "../../Common/types";
import { errorMessageInterface } from "../../CreateProjectModal/WDProjectModal";
import { apiClient, authorise } from "../../utils/apiClient";
import { UpdateStepOnePage } from "./UpdateStepOnePage";

interface EditProjectModalProps {
  modalData: ProjectInterface;
  setModalData: (val: ProjectInterface) => void;
  setLoading: (val: boolean) => void;
}

export const EditProjectModal: React.FC<EditProjectModalProps> = ({
  modalData,
  setLoading,
  setModalData,
}) => {
  const [isForCampaign, setIsForCampaign] = useState<boolean>(
    modalData.isForCampaign
  );
  const [updatedData, setUpdatedData] = useState<ProjectInterface>(modalData);
  const [initialModalData, setInitialModalData] =
    useState<ProjectInterface>(modalData);
  const errorContext = useContext(ErrorContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure();

  const [errorMessage, setErrorMessage] = useState<errorMessageInterface>({
    name: "",
    description: "",
  });
  const onCloseBigModal = () => {
    if (JSON.stringify(updatedData) === JSON.stringify(initialModalData))
      reset();
    else {
      onOpenConfirm();
    }
  };

  const reset = () => {
    setInitialModalData(modalData);
    setUpdatedData(modalData);
    setErrorMessage({
      name: "",
      description: "",
    });
    onClose();
  };
  const onClickHandler = () => {
    if (updatedData?.name === "" || updatedData?.description === "") {
      setErrorMessage({
        name: requiredField(updatedData?.name),
        description: requiredField(updatedData?.description ?? ""),
      });
    } else {
      setLoading(true);

      apiClient
        .post(
          "/api/private/projects/update",
          {
            id: updatedData.projectId,
            name: updatedData.name,
            description: updatedData.description,
            status: updatedData.status,
            icon: updatedData.icon,
            isForCampaign: isForCampaign,
          },
          authorise()
        )
        .then((res) => {
          setErrorMessage({
            name: "",
            description: "",
          });
          onClose();
          setLoading(false);
          setModalData({
            ...updatedData,
            isForCampaign: isForCampaign,
          });
        })
        .catch((err) => {
          errorContext?.createError([...err.response.data]);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (updatedData.name !== "") {
      setErrorMessage({ name: "", description: errorMessage.description });
    }

    if (updatedData.description !== "") {
      setErrorMessage({
        name: errorMessage.name,
        description: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData]);
  const colorBodyMain = useColorModeValue("white", "darkThemeGrey.700");
  const colorBodySecond = useColorModeValue("blue.100", "darkThemeGrey.600");
  const colorText = useColorModeValue("blue.700", "darkThemeGrey.100");
  const colorButton = useColorModeValue("blue.700", "blue.400");

  return (
    <>
      <Button
        onClick={onOpen}
        w={"100%"}
        textAlign={"left"}
        variant="ghost"
        justifyContent={"start"}
        fontWeight={"normal"}
      >
        Edit project
      </Button>

      <Modal
        scrollBehavior={"inside"}
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onCloseBigModal}
        size={"3xl"}
      >
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent bg={colorBodyMain} color={colorText}>
          <ModalHeader
            borderRadius={"8px 8px 0px 0px"}
            backgroundColor={colorBodySecond}
          >
            Edit project
          </ModalHeader>

          <ModalCloseButton
            onClick={onCloseBigModal}
            margin={"5px 8px 0px 0px"}
            color={colorText}
            borderWidth={2}
            borderRadius={5}
            borderColor={colorText}
          />
          <ModalBody padding={5}>
            <UpdateStepOnePage
              error={errorMessage}
              changeModalDataHandler={setUpdatedData}
              value={updatedData}
            />
          </ModalBody>
          <ModalFooter>
            <Flex width="50%" align="center" justify="center">
              <Text marginRight={2}>Is for Campaign?</Text>
              <Switch
                size="md"
                isChecked={isForCampaign}
                onChange={() => {
                  setIsForCampaign(!isForCampaign);
                }}
                _focus={{
                  boxShadow: "none",
                }}
              />
            </Flex>
            <Button
              backgroundColor={colorButton}
              colorScheme={"blue"}
              color={"white"}
              fontSize={"sm"}
              width={"50%"}
              mr={3}
              onClick={onClickHandler}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalCloseButton
          onClick={onCloseConfirm}
          margin={"5px 8px 0px 0px"}
          color="blue.800"
          borderWidth={2}
          borderRadius={5}
          borderColor={"blue.800"}
        />
        <ModalOverlay backdropFilter="blur(10px)" backdropBlur={"10px"} />

        <ModalContent>
          <ModalBody bg={colorBodyMain}>
            <VStack>
              <Box
                fontSize={20}
                textAlign="left"
                w="100%"
                fontWeight={600}
                py={2}
              >
                Discard all changes?
              </Box>
              <Box fontSize={16} textAlign="left" w="100%" fontWeight={400}>
                We won’t be able to save your data if you move away from this
                page.
              </Box>
              <Spacer />
              <HStack w="100%">
                <Spacer />
                <Box>
                  <Button tabIndex={2} onClick={onCloseConfirm}>
                    {" "}
                    Go back
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      reset();
                      onCloseConfirm();
                    }}
                    colorScheme="danger"
                    bg="danger.500"
                    tabIndex={1}
                    autoFocus
                  >
                    Exit
                  </Button>
                </Box>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
