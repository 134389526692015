import {
  Box,
  Select,
  SelectProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { theme } from "../../theme";
import { SelectOptionsInterface } from "./types";

interface WDSelectProps extends Omit<SelectProps, "onChange"> {
  onChange: (val: string) => void;
  error?: string;
  options: SelectOptionsInterface[];
  value: string;
  placeholder?: string;
  isDisabled?: boolean;
}

export const WDDropdown: React.FC<WDSelectProps> = (props) => {
  const {
    isDisabled,
    placeholder,
    defaultValue,
    onChange,
    options,
    error,
    value,

    ...others
  } = props;
  const [selectValue, setSelectValue] = useState<string>(value);

  const colorFocusBorder = useColorModeValue("blue.800", "blue.400");
  const borderColor = useColorModeValue("gray.300", "darkThemeGrey.900");
  const dangerColor = useColorModeValue("danger.500", "darkThemeRed.400");
  const bgOptionColor = useColorModeValue(
    "white",
    `${theme.colors.darkThemeGrey[100]}`
  );
  const colorBackGround = useColorModeValue("white", "darkThemeGrey.100");
  const [didMount, setDidMount] = useState<boolean>(false);

  const changeEventHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (didMount && e.target.value !== value) {
      if (
        options.filter((option) => {
          return e.target.value === option.value;
        })[0]
      ) {
        onChange(
          options.filter((option) => {
            return e.target.value === option.value;
          })[0].value
        );
      }
    }
  };

  // useEffect(() => {
  //   if (didMount && e.target.value !== value) {
  //     if (
  //       options.filter((option) => {
  //         return e.target.value === option.value;
  //       })[0]
  //     ) {
  //       onChange(
  //         options.filter((option) => {
  //           return e.target.value === option.value;
  //         })[0].value
  //       );
  //     }
  //   }
  //   console.log(selectValue);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectValue]);
  useEffect(() => {
    setSelectValue(value);
  }, [value]);
  useEffect(() => {
    const optionsValues = options.map((op) => {
      return op.value;
    });

    if (!optionsValues.includes(selectValue)) {
      setSelectValue("");
    }

    setDidMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return didMount ? (
    <Box w="100%" h="100%">
      <Select
        bg={colorBackGround}
        size={"lg"}
        _disabled={{ color: "black" }}
        _placeholder={{ color: "black" }}
        focusBorderColor={error ? dangerColor : colorFocusBorder}
        borderColor={error ? dangerColor : borderColor}
        fontSize={16}
        disabled={isDisabled ?? false}
        iconColor="black"
        color={
          selectValue === ""
            ? "neutralGrey"
            : options.filter((option) => {
                return selectValue === option.value;
              })[0]
            ? options.filter((option) => {
                return selectValue === option.value;
              })[0].color
            : "neutralGrey"
        }
        value={value}
        onChange={changeEventHandler}
        {...others}
      >
        <option style={{ display: "none" }} value={"none"}>
          {placeholder ?? "Select..."}
        </option>
        {options.map((op) => {
          return (
            <option
              value={op.value}
              key={op.value}
              style={{
                background: bgOptionColor,
                color: op.color ? op.color : colorFocusBorder,
                fontWeight: "500",
              }}
            >
              {op.display}
            </option>
          );
        })}
      </Select>
      <Box h={2}>{error && <Text color={"danger.500"}>{error}</Text>}</Box>
    </Box>
  ) : null;
};
