import {
  Box,
  Button,
  Flex,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { cammelCaseToNormal } from "../../../Utils/formaters";
import { requiredField } from "../../../Utils/ValidatorsForInput";
import { WDInput } from "../../Common/WDInput";
import {
  ModalServiceInterface,
  ServiceErrorInterface,
  serviceTypeFieldInterface,
} from "../type";

interface StringFieldProps {
  field: serviceTypeFieldInterface;
  errors: ServiceErrorInterface;
  setErrors: (val: ServiceErrorInterface) => void;
  values: ModalServiceInterface;
  setValues: (val: ModalServiceInterface) => void;
}

export const StringField: React.FC<StringFieldProps> = ({
  field,
  errors,
  setErrors,
  values,
  setValues,
}) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <Box w="100%">
      <Flex>
        <Text>{cammelCaseToNormal(field.name)}</Text>
        {field.name !== "query" && field.name !== "database" && field.name !== "certificate" && <Text color="danger.500">*</Text>}
      </Flex>
      <InputGroup>
        <WDInput
          type={
            field.name === "password" ? (show ? "text" : "password") : "text"
          }
          tabIndex={1}
          placeholder={cammelCaseToNormal(field.name)}
          value={values.serviceTypeField
            .filter((f) => {
              return f.name === field.name;
            })[0]
            ?.value?.toString()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
              ...values,
              serviceTypeField: values.serviceTypeField.map((f) => {
                if (f.name === field.name) {
                  return {
                    ...f,
                    value: e.target.value,
                  };
                } else {
                  return f;
                }
              }),
            });

            if (field.name !== "query" && field.name !== "database" && field.name !== "certificate") {
              setErrors({
                ...errors,
                serviceTypeField: errors.serviceTypeField.map((err) => {
                  if (err.name === field.name) {
                    return {
                      ...err,
                      message: requiredField(e.target.value),
                    };
                  } else {
                    return err;
                  }
                }),
              });
            }
          }}
          error={
            errors.serviceTypeField.filter((err) => {
              return err.name === field.name;
            })[0]?.message
          }
        />
        {field.name === "password" && (
          <InputRightElement width="4.5rem" marginTop="0.25rem">
            <Button
              size="sm"
              onClick={() => {
                setShow(!show);
              }}
            >
              {show ? "Hide" : "Show"}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  );
};
