import { TopicInterface } from "./types";

export const contentObject: TopicInterface[] = [
  {
    id: "topic1",
    title: "I can't authentificate from home",
    description: [
      {
        text: "WatchDog Web can only be access from a LAN (Local Area Network), in order to do this you have 3 options:",
        imageURL: "/Authenticate.svg",
      },
      {
        bold: true,
        text: "1. You downoload the mobile app and we strongly recommend you to do so.",
        marginRight: 2,
      },
      { text: "iOS: link...", marginRight: 4 },
      { text: "Android:link... ", marginRight: 4 },
      {
        bold: true,
        text: "2. You configure the VPN, for details get in touch with Iulian Florescu or Jason Fisher.",
        marginRight: 2,
      },
      { bold: true, text: "3. Or.. you come to our office ", marginRight: 2 },
    ],
  },
  {
    id: "topic2",
    title: "How do I check yesterday's status",
    description: [
      {
        text: 'To check the status of an entity, you need to navigate to "Project Details" page, then click on "Check Server Logs", after that you can see the status on a specific timeframe.',
      },
      { text: "", imageURL: "/CheckServerLogs.jpg" },
      {
        text: "More importanly you can hover or click on a error/warning zone to see the specific time. If a zone is gray it means that over a period of time we stopped from monitoring and sending notifications to mobile, also if a zone is blue it means that a deploy was done(quick note a deploy can't exceed 15 minutes).",
      },
      { text: "", imageURL: "/LogsOnSpecific.jpg" },
    ],
  },
  {
    id: "topic3",
    title: "What happens if WatchDog is down?",
    description: [
      {
        text: "That's a really good question, in Networking we have this thing called Fault Tolerance which consists in a back-up plan so that the user does not notice any change.",
      },
      {
        text: "That is our case too, we have a second WatchDog that monitores the main WatchDog and if anything happens they switch places.",
        imageURL: "/WatchDogDown.svg",
      },
    ],
  },
  {
    id: "topic4",
    title: "How can I create a project?",
    description: [
      {
        text: 'To create a project click on "+ New Project" button, add a suggestive title and description, continue on we need to add users specifying role and notification level.  ',
      },

      {
        text: 'There are 4 levels of notification ranked from the lowest to the highest. Maybe you are asking yourself: "How it\'s helping me?" and the answer is pretty simple, let\'s say you chose "Red Alert", that means you will get notified only when a server response with a "Red Alert" or "Critical" event.',
        imageURL: "/CreateProject.jpg",
      },
      { text: "", imageURL: "/NotificationLevel.jpg" },
    ],
  },
  {
    id: "topic5",
    title: "How can I add somebody else after the project was created?",
    description: [
      {
        text: " Firstly, you need to make sure they are added in the WatchDog DataBase, if they aren't you should contact an Admin.",
        imageURL: "/AddUser.svg",
      },

      {
        text: " Secondly, if you want them added on a specific project you can do that by navigating to Project Details -> Project's Settings -> Manage Users. *Note. Only an Admin or a Designer can do that.",
      },
    ],
  },
  {
    id: "topic6",
    title: "How can I add a server?",
    description: [
      {
        text: 'You can add a server by choosing Project\'s Settings -> Add a server in "Your Projects" or press "Add new server" button in Project Details. *Note: On Enviroments Select you can type your own custom option.',
      },
      {
        text: "Now on the next page we have an Entity's Job, we are going to see this type of page in Services and Tasks too so I'm going to explain it a little bit.",
      },
      {
        bold: true,
        text: "• Enabled -> should we activate it?",
        marginRight: 2,
      },
      {
        bold: true,
        text: "• Start Date -> when should we start monitoring?",
        marginRight: 2,
      },
      {
        bold: true,
        text: "• Reccuring Time -> how often should we check?",
        marginRight: 2,
      },
      {
        bold: true,
        text: "• Notification Level -> to which types of project users should we send notification?",
        marginRight: 2,
      },
      {
        bold: true,
        text: "• Timeout -> after how much time should we give a bigger alarm?",
        marginRight: 2,
      },
      {
        bold: true,
        text: "• Timeout Notification Level -> to which types of project users should we send notification?",
        marginRight: 2,
        imageURL: "/CreateJob.jpg",
      },
    ],
  },
  {
    id: "topic7",
    title: "How can I add a service?",
    description: [
      {
        text: "In order to add a service go to Project Details -> Select the Server -> Add New Service and it's over, all it's left to do is to complete the required fiels. *Note: ipV6 is not supported yet.",
        imageURL: "/CreateService.jpg",
      },
    ],
  },
  {
    id: "topic8",
    title: "How can I add a task?",
    description: [
      {
        text: "In order to add a task go to Project Details -> Select Service -> Add Task and it's over, all is left to do is to complete the required fiels.",
      },
      {
        text: "Because tasks are a crucial part of our app I'm going to present some extra-features that can help a developer.",
      },
      {
        bold: true,
        text: "• Firstly, on Generic Task we have a Choose File button that helps you import non human readable data.",
        marginRight: 2,
      },
      {
        bold: true,
        text: '• Secondly, we have the test button that generates a request with the data we completed so far and uses the response to fill "Response Body" field',
        marginRight: 2,
      },
      {
        bold: true,
        text: "• Thirdly, on Web API task we have the import curl button that let's you import a curl from Swagger on Postman and autocompletes your fields for you. *Note: After that you can press test and everything works magically.",
        marginRight: 2,
        imageURL: "/TaskGeneric.jpg",
      },
      { text: "", imageURL: "/TaskWebAPI.jpg" },
    ],
  },
  {
    id: "topic9",
    title: "How to update/delete/disable a project/server/service/task?",
    description: [
      {
        text: "• To update any of this entities click Options -> Update and a similiar modal form will appear.",
        bold: true,
      },
      {
        text: "• To delete any of this entities click Option -> Delete and a confirmation will appear.",

        bold: true,
      },
      {
        text: "• To disable any of this entities click Option -> Disable and a confirmation will appear. We only do that if we want to stop monitoring.",
        imageURL: "/EditEntity.svg",
        bold: true,
      },
    ],
  },
  {
    id: "topic10",
    title: "How to enable light/dark mode?",
    description: [
      {
        text: "Well, that's a pretty easy one..",
        imageURL: "/light-dark.jpg",
      },
    ],
  },
];
