import { theme } from "../../theme";

export const CampaignStatus = [
  { value: 0, label: "In progress", color: theme.colors.blue[600] },
  { value: 10, label: "Success", color: theme.colors.green[600] },
  { value: 20, label: "Failed", color: theme.colors.danger[500] },
  { value: 30, label: "Incomplete", color: theme.colors.warning[500] },
];

export const CampaignTaskStatus = [
  { value: 0, label: "Not done", color: theme.colors.neutralDarkGrey },
  { value: 10, label: "In progress", color: theme.colors.blue[600] },
  { value: 20, label: "Success", color: theme.colors.green[600] },
  { value: 30, label: "Failed", color: theme.colors.danger[500] },
];
