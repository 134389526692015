import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import React from "react";

interface StepBarProps {
  step: number;
  setStep: (val: number) => void;
  handleNextClick: () => void;
}

export const StepBar: React.FC<StepBarProps> = ({
  step,
  setStep,
  handleNextClick,
}) => {
  const colorButtonPrimary = useColorModeValue("blue.800", "blue.400");
  const colorButtonSecondary = useColorModeValue(
    "neutralGrey",
    "darkThemeGrey.100"
  );
  return (
    <Flex>
      <Box
        onClick={() => {
          setStep(0);
        }}
        width={16}
        backgroundColor={step === 0 ? colorButtonPrimary : colorButtonSecondary}
        height={2}
        borderRadius={5}
        style={{ cursor: "pointer" }}
      />
      <Box
        onClick={handleNextClick}
        width={16}
        backgroundColor={step === 1 ? colorButtonPrimary : colorButtonSecondary}
        height={2}
        borderRadius={5}
        marginLeft={1}
        style={{ cursor: "pointer" }}
      />
    </Flex>
  );
};
