import {
  Button,
  ButtonGroup,
  ComponentWithAs,
  Flex,
  IconProps,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import { theme } from "../../theme";
interface DrawerLinkElementProps {
  Icon:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    | ComponentWithAs<"svg", IconProps>;
  name: string;
  url: string;
  isAdmin: boolean;
}
export const DrawerLinkElement: React.FC<DrawerLinkElementProps> = ({
  Icon,
  isAdmin,
  name,
  url,
}) => {
  const location = useLocation();
  const colorBorder = useColorModeValue("blue.700", "blue.400");
  const contextData = useContext(UserContext);
  const navigate = useNavigate();
  const handleNavigate = (name: string) => {
    if (localStorage.accesToken) {
      navigate("/dashboard/" + name);
    } else {
      contextData?.logOut();
    }
  };
  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.darkThemeGrey[100]}`
  );
  const colorText = useColorModeValue("blue.700", "darkThemeGrey.100");
  const colorBackground = useColorModeValue("blue.100", "darkThemeGrey.600");

  return (
    <>
      {isAdmin ? (
        <Flex
          __css={{
            direction: "ltr",
          }}
          p={2}
          width="100%"
          borderRight={location.pathname.includes(url) ? "10px solid" : "0"}
          borderColor={colorBorder}
          onClick={() => {
            handleNavigate(url);
          }}
          cursor="pointer"
        >
          <ButtonGroup>
            <Button
              leftIcon={<Icon stroke={colorStroke} />}
              colorScheme="blue"
              bg={colorBackground}
              width="100%"
              color={colorText}
              variant="link"
              iconSpacing={10}
              opacity={location.pathname.includes(url) ? "100%" : "50%"}
              fontFamily="Montserrat"
              fontWeight={600}
              lineHeight={6}
            >
              {name}
            </Button>
          </ButtonGroup>
        </Flex>
      ) : null}
    </>
  );
};
