import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../../App";
import { CustomSpinner } from "../Common/Spinner";
import { NavBarHeight } from "../Dashboard/DashboardWrapper/DashboardWrapper";
import { apiClient, authorise } from "../utils/apiClient";
import { CreateNewClient } from "./CreateNewClient";
import { ClientInterface } from "./types";
import { UsersTableComponent } from "./UsersTableComponent";

export const ClientsPage: React.FC = () => {
  const errorContext = useContext(ErrorContext);
  const [loading, setLoading] = useState<boolean>(true);
  //const colorPrimary = useColorModeValue("blue.700", "darkThemeGrey.100");
  const [clients, setClients] = useState<ClientInterface[]>([]);
  const [filteredClients, setFilteredClients] = useState<ClientInterface[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");

  const getClients = async () => {
    await apiClient
      .get("/api/client/get/all", authorise())
      .then((res) => {
        setClients(res.data);
        setFilteredClients(res.data);
      })
      .catch((err) => {
        errorContext?.createError([...err.response.data]);
      });

    setLoading(false);
  };

  const handleSearchInputValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterValue(e.target.value);
  };

  const filterClients = () => {
    if (filterValue) {
      setFilteredClients(
        clients
          .filter(
            (client) =>
              `${client.lastName} ${client.firstName}`
                .toLowerCase()
                .includes(filterValue.toLowerCase()) ||
              `${client.firstName} ${client.lastName}`
                .toLowerCase()
                .includes(filterValue.toLowerCase()) ||
              client.email.toLowerCase().includes(filterValue.toLowerCase())
          )
          .sort((a, b) => {
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase())
              return -1;
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
            return 0;
          })
      );
    } else {
      setFilteredClients(clients);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    setClients(clients);
    filterClients();
  }, [filterValue, clients]);

  return (
    <Box px={6}>
      {loading ? (
        <CustomSpinner />
      ) : (
        <VStack maxH={`calc(100vh - ${NavBarHeight})`} w="100%">
          {/* <Flex justifyContent={"space-between"} m={4} h="10%" w="100%">
            <Heading color={colorPrimary}>Clients</Heading>
          </Flex> */}

          <Flex h="10%" w="100%" justifyContent={"space-between"} mt={2}>
            <InputGroup w={"100%"} my={3}>
              <InputRightElement children={<SearchIcon color="blue.800" />} />
              <Input
                color="black"
                bg="white"
                type="search"
                value={filterValue}
                onChange={handleSearchInputValueChange}
                placeholder="Search client by name or email"
              />
            </InputGroup>
            <Box mt={1}>
              <CreateNewClient setClients={setClients} clients={clients} />
            </Box>
          </Flex>

          <UsersTableComponent
            filteredUsers={filteredClients}
            setFilteredUsers={setFilteredClients}
            users={clients}
            setUsers={setClients}
          />

          {filteredClients?.length === 0 ? (
            <VStack justifyContent="center" alignItems="center" p={20}>
              <SearchIcon boxSize={20} opacity="50%" />
              <Box pl={4} pt={4} fontWeight={600} fontSize={20}>
                I can't seem to find "{filterValue}".
              </Box>
              <Box>
                Please double-check your spelling, try other search items.
              </Box>
            </VStack>
          ) : null}
        </VStack>
      )}
    </Box>
  );
};
