import {
  Box,
  Flex,
  HStack,
  InputGroup,
  Text,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { validatorNumericField } from "../../Utils/ValidatorsForInput";
import {
  ProjectInterface,
  SelectOptionsInterface,
  ServerInterface,
} from "../Common/types";
import { WDDatePicker } from "../Common/WDDatePicker";
import { WDDropdown } from "../Common/WDDropdown";
import { WDInput } from "../Common/WDInput";
import { ModalServiceInterface, ServiceErrorInterface } from "./type";
import { notificationOptions } from "./defaultObjects";

interface SecondStepProps {
  project: ProjectInterface;
  server: ServerInterface;
  values: ModalServiceInterface;
  setValues: (val: ModalServiceInterface) => void;
  errors: ServiceErrorInterface;
  setErrors: (val: ServiceErrorInterface) => void;
}

export const SecondStep: React.FC<SecondStepProps> = ({
  project,
  server,
  values,
  setValues,
  errors,
  setErrors,
}) => {
  const enableOptions: SelectOptionsInterface[] = [
    { value: "true", display: "Yes", color: "black" },
    { value: "false", display: "No", color: "black" },
  ];
  return (
    <VStack w="100%">
      <HStack w="100%" py={3}>
        <Box w="100%">
          <Text> Project's Name</Text>
          <WDInput
            isDisabled={true}
            placeholder={project.name}
            _disabled={{ bg: "#F8FAFC", color: "blue.800" }}
            border="1px solid"
            borderColor="neutralGrey"
          />
        </Box>

        <Box w="100%">
          <Text> Server's Name</Text>
          <WDInput
            placeholder={server.name}
            isDisabled={true}
            _disabled={{ bg: "#F8FAFC", color: "blue.800" }}
            border="1px solid"
            borderColor="neutralGrey"
          />
        </Box>
      </HStack>
      <HStack w="100%">
        <Box w="100%">
          <Flex>
            <Text>Enabled</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDDropdown
            options={enableOptions}
            onChange={(val: string) => {
              setValues({
                ...values,
                enabled: val === "true",
              });
            }}
            value={values.enabled.toString()}
          />
        </Box>
        <Box w="100%">
          <Flex>
            <Text> Start Date Time </Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDDatePicker
            onChange={(val: string) => {
              setValues({
                ...values,
                startDate: val,
              });
            }}
            value={values.startDate}
          />
        </Box>
        <Box w="100%">
          <Flex>
            <Text>Reccuring Time</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <InputGroup borderRadius={4} size={"lg"}>
            <InputRightElement pointerEvents="none" justifyContent="center">
              min
            </InputRightElement>
            <WDInput
              placeholder="1"
              value={values.reccuringTime.toString()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (validatorNumericField(e.target.value) === "") {
                  if (e.target.value === "") {
                    setValues({
                      ...values,
                      reccuringTime: 0,
                    });
                  } else {
                    setValues({
                      ...values,
                      reccuringTime: parseInt(e.target.value),
                    });
                  }
                }
              }}
              error={errors.reccuringTime}
            />
          </InputGroup>
        </Box>
      </HStack>
      <HStack w="100%" py={3}>
        <Box w="100%">
          <Flex>
            <Text>Notification Level</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDDropdown
            error={errors.minimumNotificationLevel}
            tabIndex={1}
            options={notificationOptions}
            onChange={(val: string) => {
              setValues({
                ...values,
                minimumNotificationLevel: parseInt(val),
              });
              setErrors({
                ...errors,
                minimumNotificationLevel:
                  parseInt(val) === 5 ? "This field is requierd!" : "",
              });
            }}
            value={values.minimumNotificationLevel.toString()}
            placeholder="Notification Level"
          />
        </Box>

        <Box w="100%">
          <Flex>
            <Text>Timeout</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <InputGroup borderRadius={4} size={"lg"}>
            <InputRightElement pointerEvents="none" justifyContent="center">
              min
            </InputRightElement>
            <WDInput
              placeholder="15"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (validatorNumericField(e.target.value) === "") {
                  if (e.target.value === "") {
                    setValues({
                      ...values,
                      timeout: 0,
                    });
                  } else {
                    setValues({
                      ...values,
                      timeout: parseInt(e.target.value),
                    });
                  }
                }
              }}
              value={values.timeout.toString()}
              error={errors.timeout}
            />
          </InputGroup>
        </Box>
        <Box w="100%">
          <Flex>
            <Text>Notification level timeout</Text>
            <Text color="danger.500">*</Text>
          </Flex>

          <WDDropdown
            tabIndex={2}
            options={notificationOptions}
            onChange={(val: string) => {
              setValues({
                ...values,
                timeoutNotificationLevel: parseInt(val),
              });
              //validation...
            }}
            value={values.timeoutNotificationLevel.toString()}
          />
        </Box>
      </HStack>
    </VStack>
  );
};
