import { Box, Flex, Tbody, Td, Tr, useColorModeValue } from "@chakra-ui/react";
import { motion, Variants } from "framer-motion";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorContext } from "../../App";
import { ReactComponent as Trash } from "../../Assets/ModalTrashIcon/trash.svg";
import { theme } from "../../theme";
import { IconProjectChooser } from "../Common/DictForIcons";
import { WDConfirmDelete } from "../Common/WDConfirmDelete";
import { OnlineStatus } from "../ProjectsComponent/OnlineStatus/OnlineStatus";
import { apiClient, authorise } from "../utils/apiClient";
import { Designer } from "./SmallComponents/Designer";
import { TableResourceComponent } from "./TableResourceComponent";
import { ProjectInterface, UserProject } from "./typesForProjects";
interface ProjectsTableDataProps {
  projectsToShow: ProjectInterface[] | undefined;
  deleteProjects: (id: string) => void;
  onSuccesManageUser: (val: UserProject[], id: string) => void;
}
export const TableBody: React.FC<ProjectsTableDataProps> = (props) => {
  const errorContext = useContext(ErrorContext);

  const handleDelete = async (id: string, onClose: () => void) => {
    await apiClient
      .delete(`/api/private/projects/${id}`, authorise())
      .then(() => {
        props.deleteProjects(id);
        onClose();
      })
      .catch((err) => {
        errorContext?.createError([...err.reponse.delete]);
      });
  };

  const handleProjectDetailsPageNavigation = (project: ProjectInterface) => {
    if (project.firstServerId === "") {
      navigate(`../dashboard/projects/${project.projectId}/no-server`, {
        replace: true,
      });
    } else {
      navigate(
        `../dashboard/projects/${project.projectId}/${project.firstServerId}`,
        {
          replace: true,
        }
      );
    }
  };
  const colorPrimary = useColorModeValue("blue.700", "blue.50");
  const colorSecondary = useColorModeValue(
    "neutralDarkGrey",
    "darkThemeGrey.100"
  );
  const colorTrashBg = useColorModeValue("white", "darkThemeGrey.700");
  const colorThird = useColorModeValue("neutralGrey", "darkThemeGrey.100");

  const rowVariants: Variants = {
    offscreen: {
      y: 30,
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };
  const MotionTr = motion(Tr);
  const navigate = useNavigate();
  return (
    <Tbody>
      {props.projectsToShow?.map((pro, i) => {
        return (
          <MotionTr
            key={pro.projectId}
            fontWeight={600}
            color={colorPrimary}
            fontSize={14}
            lineHeight={20}
            borderBottom="1px solid"
            borderColor={colorSecondary}
            minW="fit-content"
            initial={i <= 10 ? "onscreen" : "offscreen"}
            whileInView="onscreen"
            viewport={{ once: true }}
            minHeight="fit-content"
            variants={rowVariants}
          >
            <Td
              cursor="pointer"
              onClick={() => handleProjectDetailsPageNavigation(pro)}
            >
              <Flex alignItems="center" justifyContent="flex-start">
                <IconProjectChooser
                  iconIndex={pro.icon}
                  options={{ borderRadius: "500px" }}
                />

                <Box
                  ml={3}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  maxW="150px"
                  textAlign={"center"}
                >
                  {pro.name}
                </Box>
              </Flex>
            </Td>
            <Designer
              lastName={pro.designer?.lastName || "undefined"}
              firstName={pro.designer?.firstName || "undefined"}
              email={pro.designer?.email || "undefined"}
              userId={pro.designer?.userId || ""}
            />
            <Td color="neutralGrey" textAlign="right" textColor={colorThird}>
              {pro.enabledServers}/{pro.servers}
            </Td>
            <Td color="neutralGrey" textAlign="right" textColor={colorThird}>
              {pro.enabledServices}/{pro.services}
            </Td>
            <Td color="neutralGrey" textAlign="right" textColor={colorThird}>
              {pro.enabledTasks}/{pro.tasks}
            </Td>
            <Td color="neutralGrey" textColor={colorThird} textAlign="right">
              {pro.usersCount?.designers} D ● {pro.usersCount?.watchers} W
            </Td>
            <Td>
              <Flex justifyContent={"end"}>
                <TableResourceComponent resourceStatus={pro.cpuStatus}>
                  {Math.round(pro.cpu)}%
                </TableResourceComponent>
              </Flex>
            </Td>
            <Td textAlign="right">
              <Flex justifyContent={"end"}>
                <TableResourceComponent resourceStatus={pro.memoryStatus}>
                  {Math.round(pro.memory)}%
                </TableResourceComponent>
              </Flex>
            </Td>
            <Td textAlign="right">
              <Flex justifyContent={"end"}>
                <TableResourceComponent resourceStatus={pro.diskStatus}>
                  {Math.round(pro.disk)}%
                </TableResourceComponent>
              </Flex>
            </Td>
            <Td minW="fit-content">
              <Flex justifyContent={"end"}>
                <OnlineStatus status={pro.status} type={2} />
              </Flex>
            </Td>
            <Td>
              <WDConfirmDelete id={pro.projectId} handleDelete={handleDelete}>
                <Flex width="100%" backgroundColor={colorTrashBg}>
                  <Trash cursor={"pointer"} stroke={theme.colors.danger[500]} />
                </Flex>
              </WDConfirmDelete>
            </Td>
          </MotionTr>
        );
      })}
    </Tbody>
  );
};
