import { useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { ErrorContext, UserContext } from "../../App";
import { requiredField } from "../../Utils/ValidatorsForInput";
import { ProjectInterface, SelectOptionsInterface } from "../Common/types";
import { apiClient, authorise } from "../utils/apiClient";
import {
  ModalTypesInterface,
  ProjectsEmailInterface,
  RolesFromBackend,
  UsersFromBackend,
} from "./types";
import { errorMessageInterface } from "./WDProjectModal";

export const useProjectModalLogic = (
  isOnPage?: boolean,
  onSucces?: (val: ProjectInterface) => void
) => {
  const colorBody = useColorModeValue("white", "darkThemeGrey.700");
  const colorPrimary = useColorModeValue("blue.700", "blue.400");
  const colorSecondary = useColorModeValue("blue.800", "darkThemeGrey.100");

  const userContext = useContext(UserContext);
  const useError = useContext(ErrorContext);
  const [roles, setRoles] = useState<SelectOptionsInterface[]>([]);
  const [weHaveRoles, setWeHaveRoles] = useState<boolean>(false);
  const [users, setUsers] = useState<ProjectsEmailInterface[]>([]);
  const [weHaveUsers, setWeHaveUsers] = useState<boolean>(false);
  const defaultData: ModalTypesInterface = {
    name: "",
    icon: 0,
    description: "",
    users: [
      {
        id: userContext?.user?.id,
        role: roles.filter((user) => {
          return user.display.toLowerCase() === "designer";
        })[0]?.value,
        notificationLevel: "0",
      },
    ],
  };
  const [modalData, setModalData] = useState<ModalTypesInterface>(defaultData);
  const [errorMessage, setErrorMessage] = useState<errorMessageInterface>({
    name: "",
    description: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure();
  const [step, setStep] = useState<number>(0);
  const [isForCampaign, setIsForCampaign] = useState<boolean>(false);

  useEffect(() => {
    if (userContext?.user && roles.length) {
      setModalData({
        name: "",
        icon: 0,
        description: "",
        users: [
          {
            id: userContext?.user?.id,
            role: roles.filter((user) => {
              return user.display.toLowerCase() === "designer";
            })[0]?.value,
            notificationLevel: "0",
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext?.user, roles]);

  const onCloseBigModal = () => {
    if (JSON.stringify(modalData) === JSON.stringify(defaultData)) {
      reset();
    } else {
      onOpenConfirm();
    }
  };

  const reset = () => {
    setStep(0);
    setModalData(defaultData);
    setErrorMessage({ name: "", description: "" });
    onClose();
    setIsForCampaign(false);
  };

  const addAnotherUser = () => {
    let newUsers = modalData.users;
    newUsers.push({
      id: "",
      role: roles.filter((user) => {
        return user.display.toLowerCase() === "watcher";
      })[0].value,
      notificationLevel: "0",
    });
    setModalData({
      ...modalData,
      users: newUsers,
    });
  };

  const deleteUser = (i: number) => {
    setModalData({
      ...modalData,
      users: modalData.users.filter((user, index) => {
        return i !== index;
      }),
    });
  };

  const onClickHandler = () => {
    if (step === 0) {
      if (
        modalData?.name === "" ||
        modalData?.description === "" ||
        modalData?.name.length > 40
      ) {
        setErrorMessage({
          name: requiredField(modalData?.name)
            ? "This field can't be empty."
            : "" || modalData?.name.length > 100
            ? "This field is too long."
            : "",
          description: requiredField(modalData?.description),
        });
      } else {
        setStep(step + 1);
        setErrorMessage({
          name: "",
          description: "",
        });

        if (modalData.users.length === 1) {
          for (let i = 0; i < 3; i++) {
            addAnotherUser();
          }
        }
      }
    } else {
      reset();

      const usersToSend = modalData.users
        .filter((user, index) => {
          return index !== 0 && user.id !== "";
        })
        .map((user) => {
          return {
            userId: user.id,
            minNotificationLevel: parseInt(user.notificationLevel),
            roleId: user.role,
          };
        });

      apiClient
        .post(
          "/api/private/projects/create",
          {
            name: modalData.name,
            description: modalData.description,
            icon: modalData.icon,
            isForCampaign: isForCampaign,
            userNotificationLevel: parseInt(
              modalData.users[0].notificationLevel
            ),
            users: usersToSend,
          },
          authorise()
        )
        .then((res) => {
          if (isOnPage) {
            onSucces?.(res.data);
          } else {
            localStorage.setItem("NEW_PROJECT", "CREATED_PROJECT");
            window.location.reload();
          }
        })
        .catch((err) => useError?.createError(err.response.data));
    }
  };

  const getUsersEmails = async () => {
    await apiClient
      .get("/api/private/users/user-emails", authorise())
      .then((res) => {
        setUsers(
          res.data
            .map((user: UsersFromBackend) => {
              return {
                value: user.userId,
                label: user.email,
              };
            })
            .sort((a: ProjectsEmailInterface, b: ProjectsEmailInterface) => {
              if (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) {
                return 1;
              } else if (
                a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()
              ) {
                return -1;
              }
              return 0;
            })
        );
        setWeHaveUsers(true);
      })
      .catch((err) => {
        useError?.createError(["EMAIL"]);
      });
  };

  const getUsersRoles = async () => {
    await apiClient
      .get("/api/project-roles/all", authorise())
      .then((res) => {
        setRoles(
          res.data.map((role: RolesFromBackend) => {
            return {
              value: role.roleId,
              display: role.roleName,
              color: "black",
            };
          })
        );
        setWeHaveRoles(true);
      })
      .catch((err) => {
        useError?.createError(["ROLES"]);
      });
  };

  useEffect(() => {
    getUsersEmails();
    getUsersRoles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeModalDataHandler = (val: ModalTypesInterface) => {
    setModalData(val);
  };
  useEffect(() => {
    if (modalData.name !== "") {
      setErrorMessage({ name: "", description: errorMessage.description });
    }

    if (modalData.description !== "") {
      setErrorMessage({
        name: errorMessage.name,
        description: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData]);

  return {
    colorBody,
    colorPrimary,
    colorSecondary,
    roles,
    weHaveRoles,
    weHaveUsers,
    users,
    isOpen,
    onOpen,
    isOpenConfirm,
    onOpenConfirm,
    onCloseConfirm,
    onCloseBigModal,
    deleteUser,
    onClickHandler,
    changeModalDataHandler,
    step,
    setStep,
    modalData,
    setModalData,
    errorMessage,
    setErrorMessage,
    addAnotherUser,
    reset,
    isForCampaign,
    setIsForCampaign,
  };
};
