import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { MotionButton } from "../../../Utils/MotionComponents";
import { WDInput } from "../../Common/WDInput";
import { WDSearchSelect } from "../../Common/WDSearchSelect";
import { WDTextArea } from "../../Common/WDTextArea";
import { CampaignModalInterface } from "../types";
import { useCCModalLogic } from "./useCCModalLogic";

interface CreateCampaignModalProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  onSave: (val: CampaignModalInterface) => void;
  initialValues?: CampaignModalInterface;
}

export const CreateCampaignModal: React.FC<CreateCampaignModalProps> = ({
  isOpen,
  setIsOpen,
  onSave,
  initialValues,
}) => {
  const allData = useCCModalLogic(setIsOpen, onSave, initialValues);

  return (
    <Modal
      isOpen={isOpen}
      onClose={allData.onClose}
      scrollBehavior={"inside"}
      isCentered
      closeOnOverlayClick={false}
      size={"3xl"}
      motionPreset="slideInRight"
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalHeader
          borderRadius={"8px 8px 0px 0px"}
          backgroundColor={useColorModeValue("blue.100", "darkThemeGrey.600")}
          color={allData.colorSecondary}
        >
          New Campaign
        </ModalHeader>

        <ModalCloseButton
          onClick={allData.onClose}
          margin={"5px 8px 0px 0px"}
          borderWidth={2}
          borderRadius={5}
          color={allData.colorSecondary}
          borderColor={allData.colorSecondary}
        />

        <ModalBody
          padding={5}
          bg={useColorModeValue("white", "darkThemeGrey.700")}
        >
          {allData.loading ? (
            <Flex h={300} align="center" justify="center">
              <Spinner />
            </Flex>
          ) : (
            <Flex flexDirection={"column"}>
              <Box marginTop={5}>
                <Flex>
                  <Text>Campaign's name</Text>
                  <Text color="danger.500">*</Text>
                </Flex>
                <WDInput
                  value={allData.value.name}
                  error={allData.error?.name}
                  onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                    if (val.target.value !== "") {
                      allData.setError({ ...allData.error, name: "" });
                    }
                    allData.setValue({
                      ...allData.value,
                      name: val.target.value,
                    });
                  }}
                  placeholder="Add campaign's name here.."
                ></WDInput>
              </Box>

              <Box marginTop={5}>
                <Flex>
                  <Text>Slug</Text>
                  <Text color="danger.500">*</Text>
                </Flex>
                <WDInput
                  value={allData.value.slug}
                  error={allData.error?.slug}
                  onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                    if (val.target.value !== "") {
                      allData.setError({ ...allData.error, slug: "" });
                    }
                    allData.setValue({
                      ...allData.value,
                      slug: val.target.value,
                    });
                  }}
                  placeholder="Add campaign's slug here.."
                ></WDInput>
              </Box>

              <Flex marginTop={5} align="baseline" justify="space-between">
                <Box width="40%">
                  <Flex>
                    <Text>Campaign's Project</Text>
                    <Text color="danger.500">*</Text>
                  </Flex>
                  <Box h="50px">
                    {allData.projectsOptions.length ? (
                      <WDSearchSelect
                        isDisabled={initialValues ? true : false}
                        value={allData.value.projectId}
                        error={allData.error?.projectId}
                        options={allData.projectsOptions}
                        onChange={(val) => {
                          allData.setValue({
                            ...allData.value,
                            projectId: val ?? "",
                          });
                          allData.setError({
                            ...allData.error,
                            projectId: "",
                          });
                        }}
                      />
                    ) : (
                      <Text>No project for you!</Text>
                    )}
                  </Box>
                </Box>
                <Box w="40%">
                  <Flex>
                    <Text>Time limit</Text>
                    <Text color="danger.500">*</Text>
                  </Flex>
                  <InputGroup borderRadius={4} size={"lg"}>
                    <InputRightElement
                      pointerEvents="none"
                      justifyContent="center"
                    >
                      min
                    </InputRightElement>
                    <WDInput
                      placeholder="15"
                      onChange={allData.handleTimeout}
                      value={`${allData.value.timeLimit}`}
                      error={allData.error?.timeLimit}
                    />
                  </InputGroup>
                </Box>
              </Flex>

              <Box marginTop={5}>
                <Flex>
                  <Text>Campaign's description</Text>
                  <Text color="danger.500">*</Text>
                </Flex>

                <WDTextArea
                  value={allData.value.description}
                  placeholder="Add your description here.."
                  error={allData.error?.description}
                  onChange={(val: string) => {
                    if (val !== "") {
                      allData.setError({ ...allData.error, description: "" });
                    }
                    allData.setValue({ ...allData.value, description: val });
                  }}
                />
              </Box>
            </Flex>
          )}
        </ModalBody>
        <ModalFooter bg={useColorModeValue("white", "darkThemeGrey.700")}>
          <MotionButton
            tabIndex={1}
            backgroundColor={allData.colorPrimary}
            colorScheme={useColorModeValue("blue", "blue")}
            color={"white"}
            fontSize={"sm"}
            width={"30%"}
            mr={3}
            onClick={allData.onSaveClickHandler}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", bounce: 0.4 }}
          >
            Save
          </MotionButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
