import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorContext } from "../../App";
import { UserContextInterface } from "../../types";
import { UserInterface } from "../../types";
import { apiClient, authorise } from "./apiClient";

export const useAuth = (): UserContextInterface => {
  const errorContext = useContext(ErrorContext);
  const [user, setUser] = useState<UserInterface>({} as UserInterface);
  const navigate = useNavigate();
  const [didMount, setMount] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname !== "/login" &&
      didMount === false &&
      localStorage.accesToken
    ) {
      if (localStorage.getItem("accesToken")) {
        logIn();
      } else {
        logOut();
      }
    } else {
      setMount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const logIn = async () => {
    if (localStorage.accesToken) {
      await apiClient
        .get("/api/private/users/user-details", authorise())
        .then((res) => {
          setUser({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            roleName: res.data.roleName,
            mail: res.data.email,
            id: res.data.id,
            projects: {
              designer: res.data.projects.designer,
              watcher: res.data.projects.watcher,
            },
            profilePictureUrl: res.data.profilePictureUrl,
          });

          if (location.pathname === "/login")
            navigate("/dashboard/your-projects");
        })
        .catch((err) => {
          errorContext.createError([err.reponse]);
          logOut();
        });
    }
    setMount(true);
  };

  const logOut = () => {
    setUser({} as UserInterface);
    localStorage.removeItem("accesToken");
    navigate("/login");
    document.location.reload();
  };

  return {
    didMount,
    user,
    logIn,
    logOut,
    setUser,
  };
};
