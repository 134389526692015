import { Box, useColorModeValue } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { ErrorContext } from "../../../App";
import { theme } from "../../../theme";
import { apiClient, authorise } from "../../utils/apiClient";
import {
  BackupCampaignInterface,
  CampaignModalInterface,
  TaskModalInterface,
} from "../types";

export const useCCLogic = (
  campaign: BackupCampaignInterface,
  filteredCampaign: BackupCampaignInterface[],
  setFilteredCampaign: (val: BackupCampaignInterface[]) => void,
  campaigns: BackupCampaignInterface[],
  setCampaigns: (val: BackupCampaignInterface[]) => void
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenTask, setIsOpenTask] = useState<boolean>(false);
  const errorContext = useContext(ErrorContext);
  const colorPrimary = useColorModeValue(
    theme.colors.blue[100],
    theme.colors.green[800]
  );
  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.blue[400]}`
  );
  const colorMenu = useColorModeValue("white", "darkThemeGrey.600");

  //const navigate = useNavigate();
  const selectedBorderColor = useColorModeValue("blue.700", "white");
  // const handleNavigate = (id: string) => {
  //   if (campaign.campaignProject.firstServerId === "") {
  //     navigate(`../dashboard/projects/${id}/no-server`);
  //   } else {
  //     navigate(
  //       `../dashboard/projects/${id}/${campaign.campaignProject.firstServerId}`
  //     );
  //   }
  // };
  const MotionBox = motion(Box);

  const handleDeleteCampaign = async (id: string, onClose: () => void) => {
    await apiClient
      .delete(`/api/campaigns/delete-campaign/${id}`, authorise())
      .then((res) => {
        setFilteredCampaign(
          filteredCampaign.filter((c) => {
            return c.id !== id;
          })
        );
        setCampaigns(
          campaigns.filter((c) => {
            return c.id !== id;
          })
        );

        errorContext.createToast("Campaign deleted succesufully");
        onClose();
      })
      .catch((err) => {
        errorContext.createError([]);
      });
  };

  const handleEditCampaign = (val: CampaignModalInterface) => {
    apiClient
      .put(
        "/api/campaigns/modify-campaign",
        {
          campaignId: campaign.id,
          name: val.name,
          slug: val.slug,
          description: val.description,
          timeLimit: val.timeLimit,
        },
        authorise()
      )
      .then((res) => {
        setFilteredCampaign(
          filteredCampaign.map((c) => {
            if (c.id === campaign.id) {
              return {
                ...c,
                ...res.data,
              };
            } else {
              return c;
            }
          })
        );
        setCampaigns(
          campaigns.map((c) => {
            if (c.id === campaign.id) {
              return {
                ...c,
                ...res.data,
              };
            } else {
              return c;
            }
          })
        );
        errorContext.createToast("Campaign edited succesufully");
      })
      .catch((err) => {
        errorContext.createError([]);
      });
    setIsOpen(false);
  };

  const createTaskHandler = (val: TaskModalInterface) => {
    apiClient
      .post(
        "/api/campaigns/add-campaign-task",
        { campaignId: campaign.id, name: val.name, slug: val.slug },
        authorise()
      )
      .then((res) => {
        setFilteredCampaign(
          filteredCampaign.map((c) => {
            if (c.id === campaign.id) {
              return {
                ...campaign,
                campaignTasks: [
                  ...campaign.campaignTasks,
                  {
                    ...res.data,
                    campaignId: campaign.id,
                  },
                ],
              };
            } else {
              return c;
            }
          })
        );
        errorContext.createToast("Task added succesufully");
      })
      .catch((err) => {
        errorContext.createError([]);
      });
    setIsOpenTask(false);
  };

  return {
    isOpen,
    setIsOpen,
    isOpenTask,
    setIsOpenTask,
    colorMenu,
    colorPrimary,
    colorStroke,
    selectedBorderColor,
    //handleNavigate,
    handleEditCampaign,
    handleDeleteCampaign,
    MotionBox,
    createTaskHandler,
  };
};
