import { Box, Flex, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { UserContext } from "../../App";
import { WDProjectModal } from "../CreateProjectModal/WDProjectModal";
import { createDrawerElementsListObject } from "./drawerElementsListObject";
import { DrawerLinkElement } from "./DrawerLinkElement";

export const ListDrawer: React.FC = () => {
  const userContext = useContext(UserContext);

  return (
    <Flex flexDirection="column" align="center" width="100%" minH="120px">
      <Box pl={4} pb={4} width="100%">
        <WDProjectModal />
      </Box>
      <VStack
        overflowY="scroll"
        overflowX="hidden"
        __css={{
          direction: "rtl",
          "&::-webkit-scrollbar-track": {
            background: 'inherit',
          },
        }}
        h="100%"
        spacing="10px"
        align="start"
        width="100%"
        p={1}
        ml={2}
      >
        {createDrawerElementsListObject(
          userContext.user.roleName === "Admin"
        ).map((dr, i) => {
          return (
            <DrawerLinkElement
              key={"drawer" + i}
              name={dr.name}
              url={dr.url}
              Icon={dr.Icon}
              isAdmin={dr.isAdmin}
            />
          );
        })}
      </VStack>
    </Flex>
  );
};
