import { ComponentWithAs, IconProps } from "@chakra-ui/react";
import { ReactComponent as AllProjects } from "../../Assets/Drawer-NavBar/AllProjects.svg";
import { ReactComponent as ClientsIcon } from "../../Assets/Drawer-NavBar/clients.svg";
import { ReactComponent as ProjectsIcon } from "../../Assets/Drawer-NavBar/ProjectsIcon.svg";
import { ReactComponent as UsersIcon } from "../../Assets/Drawer-NavBar/UsersIcon.svg";
import { ReactComponent as ThresholdTemplatesIcon } from "../../Assets/Drawer-NavBar/thresholdsTemplate.svg";
import { ReactComponent as ExecutionsIcon } from "../../Assets/Drawer-NavBar/Executions.svg";

import {
  CalendarIcon,
  InfoOutlineIcon,
  QuestionIcon,
  StarIcon,
} from "@chakra-ui/icons";
export interface DrawerListInterface {
  name: string;
  url: string;
  Icon:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    | ComponentWithAs<"svg", IconProps>;
  isAdmin: boolean;
}
export const createDrawerElementsListObject = (
  isAdmin: boolean
): DrawerListInterface[] => {
  return [
    {
      name: "Your Projects",
      url: "your-projects",
      isAdmin: true,
      Icon: ProjectsIcon,
    },
    {
      name: "All Projects",
      url: "all-projects",
      isAdmin: isAdmin,
      Icon: AllProjects,
    },
    { name: "Clients", url: "clients", isAdmin: isAdmin, Icon: ClientsIcon },
    {
      name: "Developers",
      url: "developers",
      isAdmin: isAdmin,
      Icon: UsersIcon,
    },
    {
      name: "Campaigns",
      url: "campaigns",
      isAdmin: true,
      Icon: ExecutionsIcon,
    },
    {
      name: "Campaign Templates",
      url: "campaign-templates",
      isAdmin: isAdmin,
      Icon: CalendarIcon,
    },
    {
      name: "Server Templates",
      url: "server-templates",
      isAdmin: isAdmin,
      Icon: ThresholdTemplatesIcon,
    },
    {
      name: "Help Center",
      url: "help",
      isAdmin: true,
      Icon: QuestionIcon,
    },
    { name: " What's New", url: "whats-new", isAdmin: true, Icon: StarIcon },
    { name: "About us", url: "about", isAdmin: true, Icon: InfoOutlineIcon },
  ];
};
