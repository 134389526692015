import { Box, ResponsiveValue, Text, Tooltip } from "@chakra-ui/react";
import { TextAlign } from "chart.js";
import React from "react";
import { theme } from "../../../theme";

interface TaskDetailsProps {
  title: string;
  content: string;
  textAlign?: ResponsiveValue<TextAlign>;
}

export const TaskDetails: React.FC<TaskDetailsProps> = ({
  title,
  content,
  textAlign,
}) => {
  return (
    <Box textAlign={textAlign ?? "center"}>
      <Text fontWeight="600" color={theme.colors.neutralGrey} fontSize={9}>
        {title}
      </Text>
      <Tooltip hasArrow label={content} isDisabled={content?.length < 40}>
        <Text
          fontWeight={title === "TASK NAME" ? "600" : "500"}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          maxW="200px"
        >
          {content ?? "-"}
        </Text>
      </Tooltip>
    </Box>
  );
};
