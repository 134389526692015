import { Box, ResponsiveValue, Text, Tooltip } from "@chakra-ui/react";
import { TextAlign } from "chart.js";
import React from "react";

interface CampaignDetailsProps {
  title: string;
  content: string;
  textAlign?: ResponsiveValue<TextAlign>;
}

export const CampaignDetails: React.FC<CampaignDetailsProps> = ({
  title,
  content,
  textAlign,
}) => {
  return (
    <Box textAlign={textAlign ?? "center"}>
      <Text fontWeight="600" color="neutralGrey" fontSize={10}>
        {title}
      </Text>
      <Tooltip hasArrow label={content} isDisabled={content.length < 20}>
        <Text
          fontWeight={
            title === "CAMPAIGN" ||
            title === "EXECUTION" ||
            title === "PROJECT NAME"
              ? "700"
              : "500"
          }
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          maxW="250px"
        >
          {content ?? "-"}
        </Text>
      </Tooltip>
    </Box>
  );
};
