import { ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  GridItem,
  SimpleGrid,
  Spacer,
  Spinner,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ErrorContext, UserContext } from "../../App";
import { ProjectInterface } from "../Common/types";
import { apiClient, authorise } from "../utils/apiClient";

import { motion, Variants } from "framer-motion";
import { theme } from "../../theme";
import { ProjectCard } from "./ProjectCard";

export const ProjectsComponent: React.FC = () => {
  const useError = useContext(ErrorContext);
  const [loading, setLoading] = useState<Boolean>(true);
  const [projects, setProjects] = useState<ProjectInterface[]>([]);
  const userContext = useContext(UserContext);
  const MotionSimpleGrid = motion(SimpleGrid);
  const MotionGridItem = motion(GridItem);
  const borderColor = useColorModeValue("neutralGrey", "gray.200");
  const shadowColor = useColorModeValue(
    `${theme.colors.neutralGrey}`,
    `${theme.colors.green[700]}`
  );
  const cardVariants: Variants = {
    offscreen: {
      y: 140,
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  useEffect(() => {
    getProjectCards();
  }, []);

  const getProjectCards = async () => {
    await apiClient
      .get(
        `/api/private/projects/v2/my-projects/${userContext?.user?.id}`,
        authorise()
      )
      .then((res) => {
        setProjects(res.data);
      })
      .catch((err) => {
        useError?.createError([err.response.data]);
      });
    setLoading(false);
  };

  const deleteProject = (id: string) => {
    userContext.setUser({
      ...userContext.user,
      projects: {
        watcher: userContext?.user?.projects?.watcher,
        designer: userContext?.user?.projects?.designer - 1,
      },
    });
    if (projects)
      setProjects([
        ...projects?.filter((pro) => {
          return pro.projectId !== id;
        }),
      ]);
  };

  // const addAnotherProject = (project: ProjectInterface) => {
  //   useError.createToast("You have created a project");
  //   setProjects([...projects, project]);
  // };

  return (
    <Box>
      {loading ? (
        <Flex h="60vh" justifyContent={"center"} alignItems={"center"}>
          <Spinner
            size="xl"
            color="blue.500"
            thickness="4px"
            speed="0.65s"
            emptyColor="blue.100"
          />
        </Flex>
      ) : (
        <VStack h={"100vh"} w={"100%"}>
          {/* <HStack w={"100%"} py={6} pl={8} pr={6}>
            <Heading>Your Projects</Heading>
            <Spacer />

            <Box w="fit-content">
              <WDProjectModal isOnPage={true} onSucces={addAnotherProject} />
            </Box>
          </HStack> */}
          {projects?.length !== 0 ? (
            <MotionSimpleGrid
              columns={[1, 1, 1, 1, 1, 2]}
              pl={4}
              pr={10}
              gap={2}
              w={"100%"}
            >
              {projects?.map((pro, i) => {
                return (
                  <MotionGridItem
                    m={4}
                    key={i + "-" + pro.projectId}
                    w="100%"
                    position="relative"
                    transition="0.5s"
                    border="1px solid"
                    borderColor={pro.status === 20 ? "danger.500" : borderColor}
                    boxShadow={`0px 0px 6px ${shadowColor}`}
                    borderRadius={8}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true }}
                    minHeight="fit-content"
                    variants={cardVariants}
                  >
                    <VStack w="100%">
                      <ProjectCard
                        project={pro}
                        setProjects={setProjects}
                        projects={projects}
                        deleteProject={deleteProject}
                      />

                      <Spacer />
                      {/* <ProjectCardFooter
                        project={pro}
                        deleteProject={deleteProject}
                        projects={projects}
                        setProjects={setProjects}
                      /> */}
                    </VStack>
                  </MotionGridItem>
                );
              })}
            </MotionSimpleGrid>
          ) : (
            <VStack justifyContent="center" alignItems="center" p={20}>
              <ViewOffIcon boxSize={20} opacity="50%" />
              <Box pl={4} pt={4} fontWeight={600} fontSize={20}>
                You don't have any projects.
              </Box>
            </VStack>
          )}
        </VStack>
      )}
    </Box>
  );
};
