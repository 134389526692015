import { Box } from "@chakra-ui/react";
import React, { createContext, useEffect } from "react";
import { CustomSpinner } from "../../Common/Spinner";
import { ResourceDetailsHeader } from "./ResourceDetailsHeader";
import { TabPanelContent } from "./TabPanelContent";
import { ResourceDetailsContextInterface } from "./types";
import { useResourceDetails } from "./useResourceDetails";

export const ResourceDetailsContext =
  createContext<ResourceDetailsContextInterface>(
    {} as ResourceDetailsContextInterface
  );
export const ResourcePageDetails: React.FC = () => {
  const resourceHook = useResourceDetails();
  useEffect(() => {
    resourceHook.initializePage();
  }, []);
  return (
    <ResourceDetailsContext.Provider value={resourceHook}>
      {resourceHook.loadingPage ? (
        <CustomSpinner />
      ) : (
        <>
          <Box overflow="scroll" h="100%">
            <ResourceDetailsHeader />
            <TabPanelContent />
          </Box>
        </>
      )}
    </ResourceDetailsContext.Provider>
  );
};
