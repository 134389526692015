import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GridObjectInterface,
  LogGridPropsInterface,
  StartEndInterface,
  TimeFrameInterface,
} from "../ServerLogsPage/types";
import { TaskDetailsInterface, TasksLogsDataContextInterface } from "./types";
import moment from "moment";
import { apiClient, authorise } from "../utils/apiClient";
import { ErrorContext } from "../../App";

export const useTaskLogsData = (): TasksLogsDataContextInterface => {
  const errorContext = useContext(ErrorContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { taskId } = useParams();
  const { tabName } = useParams();

  const [tabIndex, setTabIndex] = useState<number>(
    tabName === "last-24h" ? 0 : tabName === "last-week" ? 1 : 2
  );
  const [numberOfCells, setNumberOfCells] = useState<number>(0);
  const [timeFrame, setTimeFrame] = useState<TimeFrameInterface>({
    start: "",
    end: "",
  });
  const [taskDetails, setTaskDetails] = useState<TaskDetailsInterface>(
    {} as TaskDetailsInterface
  );
  const [loadingTaskLogs, setLoadingTaskLogs] = useState<boolean>(true);

  const getIndexesFunction = (
    startEnd: StartEndInterface[]
  ): GridObjectInterface[] => {
    const numberToDivide = tabIndex === 0 ? 15 : tabIndex === 1 ? 60 : 240;
    return startEnd?.map((startEndObject) => {
      return {
        startOfGrid:
          Math.floor(startEndObject.start / numberToDivide) ===
          startEndObject.start / numberToDivide
            ? startEndObject.start / numberToDivide
            : Math.floor(startEndObject.start / numberToDivide),
        endOfGrid:
          Math.ceil(startEndObject.end / numberToDivide) ===
          startEndObject.end / numberToDivide
            ? startEndObject.end / numberToDivide
            : Math.ceil(startEndObject.end / numberToDivide),
      };
    });
  };

  const getColorArray = (
    arrayForRedColoring: GridObjectInterface[],
    arrayForGreyColoring: GridObjectInterface[],
    arrayForYellowColoring?: GridObjectInterface[],
    arrayForBlueColoring?: GridObjectInterface[],
    arrayForDarkGreyColoring?: GridObjectInterface[]
  ): string[] => {
    const arrWithColors = new Array<string>(
      tabIndex === 0 ? 96 : tabIndex === 1 ? 168 : 180
    ).fill("green.500");
    const nmbOfCells = tabIndex === 0 ? 96 : tabIndex === 1 ? 168 : 180;

    arrayForDarkGreyColoring?.forEach((unknown) => {
      const endOfDarkGrey = Math.min(unknown.endOfGrid, nmbOfCells - 1);
      for (let i = unknown.startOfGrid; i <= endOfDarkGrey; i++) {
        arrWithColors[i] = "neutralDarkGrey";
      }
    });
    arrayForYellowColoring?.forEach((warning) => {
      const endOfYellow = Math.min(warning.endOfGrid, nmbOfCells - 1);
      for (let i = warning.startOfGrid; i <= endOfYellow; i++) {
        arrWithColors[i] = "warning.400";
      }
    });
    arrayForRedColoring?.forEach((downtime) => {
      const endOfRed = Math.min(downtime.endOfGrid, nmbOfCells - 1);
      for (let i = downtime.startOfGrid; i <= endOfRed; i++) {
        arrWithColors[i] = "danger.400";
      }
    });
    arrayForGreyColoring?.forEach((disabled) => {
      const endOfGrey = Math.min(disabled.endOfGrid, nmbOfCells - 1);
      for (let i = disabled.startOfGrid; i <= endOfGrey; i++) {
        arrWithColors[i] = "gray.400";
      }
    });
    arrayForBlueColoring?.forEach((deploy) => {
      const endOfBlue = Math.min(deploy.endOfGrid, nmbOfCells - 1);
      for (let i = deploy.startOfGrid; i <= endOfBlue; i++) {
        arrWithColors[i] = "blue.400";
      }
    });

    return arrWithColors;
  };

  const getLogGridColorAndColSpan = (
    arrWithColor: string[]
  ): LogGridPropsInterface[] => {
    const arrWithColorAndColSpan = new Array<LogGridPropsInterface>();
    const nmbOfCells = tabIndex === 0 ? 96 : tabIndex === 1 ? 168 : 180;
    let len = 1;
    let sum = 0;
    for (let i = 0; i < arrWithColor.length; i++) {
      if (arrWithColor[i] === arrWithColor[i + 1]) {
        len++;
      } else {
        sum = sum + len;
        if (sum <= nmbOfCells) {
          arrWithColorAndColSpan.push({
            start: i - len + 1,
            end: i,
            color: arrWithColor[i],
            colSpan: len,
          });
          len = 1;
        } else {
          break;
        }
      }
    }
    return arrWithColorAndColSpan;
  };

  const getLogBar = (
    downtime: StartEndInterface[],
    disabled: StartEndInterface[],
    unexpectedBehaviour: StartEndInterface[],
    deploy: StartEndInterface[],
    unknown: StartEndInterface[]
  ) => {
    const indexesDowntimeArray = getIndexesFunction(downtime);
    const indexesDisabledArray = getIndexesFunction(disabled);
    const indexesUnexpectedBehaviourArray =
      getIndexesFunction(unexpectedBehaviour);
    const indexesDeployArray = getIndexesFunction(deploy);
    const indexesUnknownArray = getIndexesFunction(unknown);
    const colorArray = getColorArray(
      indexesDowntimeArray,
      indexesDisabledArray,
      indexesUnexpectedBehaviourArray,
      indexesDeployArray,
      indexesUnknownArray
    );
    return getLogGridColorAndColSpan(colorArray);
  };

  useEffect(() => {
    if (tabIndex === 0) {
      setNumberOfCells(96);
      getTaskLogsForLast24h();
      setTimeFrame({
        start: moment().subtract(24, "hours").format("HH:mm"),
        end: moment().format("HH:mm"),
      });
    }
    if (tabIndex === 1) {
      setNumberOfCells(168);
      getTaskLogsForLastWeek();
      setTimeFrame({
        start: moment().subtract(1, "week").format("dddd D MMM"),
        end: moment().format("dddd D MMM"),
      });
    }
    if (tabIndex === 2) {
      setNumberOfCells(180);
      getTaskLogsForLastMonth();
      setTimeFrame({
        start: moment().subtract(1, "months").format("dddd D MMM"),
        end: moment().format("dddd D MMM"),
      });
    }
  }, [tabIndex]);

  const getTaskLogsForLast24h = async () => {
    setLoadingTaskLogs(true);
    await apiClient
      .get(`api/private/log/task-last-24h/${taskId}`, authorise())
      .then((res) => {
        console.log(res.data);
        setTaskDetails(res.data);
      })
      .catch((err) => {
        errorContext.createError(err.response.data);
      });
    setLoading(false);
    setLoadingTaskLogs(false);
  };

  const getTaskLogsForLastWeek = async () => {
    setLoadingTaskLogs(true);
    await apiClient
      .get(`api/private/log/task-last-week/${taskId}`, authorise())
      .then((res) => {
        console.log(res.data);
        setTaskDetails(res.data);
      })
      .catch((err) => {
        errorContext.createError(err.response.data);
      });
    setLoading(false);
    setLoadingTaskLogs(false);
  };

  const getTaskLogsForLastMonth = async () => {
    setLoadingTaskLogs(true);
    await apiClient
      .get(`api/private/log/task-last-month/${taskId}`, authorise())
      .then((res) => {
        setTaskDetails(res.data);
      })
      .catch((err) => {
        errorContext.createError(err.response.data);
      });
    setLoading(false);
    setLoadingTaskLogs(false);
  };

  return {
    loading,
    tabIndex,
    setTabIndex,
    timeFrame,
    getLogBar,
    numberOfCells,
    taskDetails,
    loadingTaskLogs,
  };
};
