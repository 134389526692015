//import { validatorNumericField } from "./ValidatorsForInput";

export const formatIpV4 = (value: string): string => {
  return value;
  // if (validatorNumericField(value)) return value.slice(0, -1);
  // else {
  //   const valueSplit = value.split(".");
  //   if (valueSplit.length > 4) {
  //     return value.slice(0, -1);
  //   }
  //   if (
  //     valueSplit.length === 4 &&
  //     valueSplit[valueSplit?.length - 1]?.length > 3
  //   ) {
  //     return value.slice(0, -1);
  //   }
  //   if (
  //     value[value.length - 1] === "." &&
  //     valueSplit[valueSplit?.length - 2]?.length < 1
  //   ) {
  //     return value.slice(0, -1);
  //   } else if (
  //     valueSplit[valueSplit?.length - 1]?.length === 4 &&
  //     valueSplit.length < 4
  //   ) {
  //     return value.slice(0, -1) + "." + value.slice(-1);
  //   }

  //   return value;
  // }
};

export const cammelCaseToNormal = (text: string) => {
  return (
    text
      .replace(/([A-Z])/g, " $1")
      .charAt(0)
      .toUpperCase() + text.replace(/([A-Z])/g, " $1").slice(1)
  );
};

export const booleanConverter = (val: string | number | boolean): boolean => {
  if (typeof val === "string") return val === "true";

  if (typeof val === "number") return val !== 0;

  return val;
};
