import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { ResourceOverviewContext } from ".";
import { ProcessorRow } from "./ProcessorRow";
export const ServerMetrics: React.FC = () => {
  const { serverMetrics } = useContext(ResourceOverviewContext);
  const shadowColor = useColorModeValue("#000", "#fff");
  return (
    <VStack
      w="100%"
      justify={"flex-start"}
      mt={10}
      px={2}
      color="blue.700"
      boxShadow={`0px 0px 6px ${shadowColor}`}
    >
      <Box w="100%" fontSize={24} fontWeight={"bold"}>
        {" "}
        Server's Metrics
      </Box>
      <Accordion w="100%" allowToggle>
        <AccordionItem w="100%">
          <AccordionButton w="100%">
            <HStack w="100%" justify={"space-between"}>
              {" "}
              <Flex justify={"center"} fontSize={20} fontWeight={"bold"}>
                Arhitecture: {serverMetrics?.cpuDetails?.architecture}
              </Flex>
              <Flex justify={"center"} fontSize={20} fontWeight={"bold"}>
                Memory Size:{" "}
                {((serverMetrics?.memorySizeKB || 0) / 1048576).toFixed(2)} GB
              </Flex>
              <Flex justify={"center"} fontSize={20} fontWeight={"bold"}>
                HDD Size:{" "}
                {((serverMetrics?.hddSizeKB || 0) / 1048576).toFixed(2)} GB
              </Flex>
              <Flex justify={"center"} fontSize={20} fontWeight={"bold"}>
                {serverMetrics?.numberOfCores === 1 ? " Core: " : " Cores: "}
                {serverMetrics?.numberOfCores}
              </Flex>
            </HStack>
          </AccordionButton>
          <AccordionPanel>
            <Flex w="100%" justify="center" fontSize={28} fontWeight={"bold"}>
              Processors
            </Flex>
            {serverMetrics?.cpuDetails ? (
              <ProcessorRow processor={serverMetrics?.cpuDetails} />
            ) : null}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};
