import { SearchIcon } from "@chakra-ui/icons";
import { Box, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ErrorContext, UserContext } from "../../App";
import { CustomSpinner } from "../Common/Spinner";
import { NavBarHeight } from "../Dashboard/DashboardWrapper/DashboardWrapper";
import { apiClient, authorise } from "../utils/apiClient";
import { AllProjectsHeader } from "./AllProjectsHeader";
import { AllProjectsTable } from "./AllProjectsTable";
import { ProjectInterface, UserProject } from "./typesForProjects";

export const AllProjectsWrapper: React.FC = () => {
  const userContext = useContext(UserContext);
  const [filterValue, setFilterValue] = useState<string | undefined>("");
  const [allProjectsUnfiltred, setProjects] = useState<
    ProjectInterface[] | undefined
  >();

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFiltredProjects(sortProjects(allProjectsUnfiltred));
  }, [allProjectsUnfiltred]);
  const [allProjectsFiltered, setFiltredProjects] = useState<
    ProjectInterface[] | undefined
  >(allProjectsUnfiltred);
  const deleteProjects = (id: string) => {
    if (allProjectsUnfiltred)
      setProjects([
        ...allProjectsUnfiltred?.filter((p) => {
          if (p.projectId === id)
            userContext.setUser({
              ...userContext.user,
              projects: {
                watcher:
                  p.role.toLocaleLowerCase() === "watcher"
                    ? userContext?.user?.projects?.watcher - 1
                    : p.role.toLocaleLowerCase() === "watcher"
                    ? userContext?.user?.projects?.watcher - 1
                    : userContext?.user?.projects?.watcher,
                designer:
                  p.role.toLocaleLowerCase() === "designer"
                    ? p.role.toLocaleLowerCase() === "designer"
                      ? userContext?.user?.projects?.designer - 1
                      : userContext?.user?.projects?.designer
                    : userContext?.user?.projects?.designer,
              },
            });
          return p.projectId !== id;
        }),
      ]);
  };
  const sortProjects = (allProjects: ProjectInterface[] | undefined) => {
    allProjects?.sort((a, b) => {
      if (a.status < b.status) {
        return 1;
      }
      if (a.status > b.status) {
        return -1;
      }
      if (
        a.usersCount.designers + a.usersCount.watchers >
        b.usersCount.designers + b.usersCount.watchers
      )
        return -1;
      if (
        a.usersCount.designers + a.usersCount.watchers <
        b.usersCount.designers + b.usersCount.watchers
      )
        return 1;

      return a.name.replace(/ /g, "").localeCompare(b.name.replace(/ /g, ""));
    });

    return allProjects;
  };

  const [loading, setLoading] = useState<Boolean>(true);
  const errorContext = useContext(ErrorContext);
  const getProjects = async () => {
    await apiClient
      .get("/api/private/projects/all", authorise())
      .then((res) => {
        setProjects(res.data);
      })
      .catch((err) => {
        errorContext.createError([...err.response.data]);
      });
    setLoading(false);
  };
  const onSuccesManageUser = (val: UserProject[], id: string) => {
    if (allProjectsUnfiltred)
      setProjects([
        ...allProjectsUnfiltred?.map((pro) => {
          if (pro.projectId === id) return { ...pro, usersDetails: val };
          else return pro;
        }),
      ]);
  };

  return (
    <Box w="100%" h="100%" maxH={`calc(100vh - ${NavBarHeight})`} py={6}>
      {loading ? (
        <CustomSpinner />
      ) : (
        <VStack w="100%" maxH={`calc(100vh - ${NavBarHeight})`} px={3}>
          <AllProjectsHeader
            unfiltredProjects={allProjectsUnfiltred}
            setFilter={setFiltredProjects}
            setNameFilter={setFilterValue}
          />

          <AllProjectsTable
            onSuccesManageUser={onSuccesManageUser}
            projectsToShow={allProjectsFiltered}
            deleteProjects={deleteProjects}
          />

          {allProjectsFiltered?.length === 0 ? (
            <VStack justifyContent="center" alignItems="center" p={20}>
              <SearchIcon boxSize={20} opacity="50%" />
              <Box pl={4} pt={4} fontWeight={600} fontSize={20}>
                I can't seem to find "{filterValue}"
              </Box>
              <Box>
                Please double-check your spelling, try other search items
              </Box>
            </VStack>
          ) : null}
        </VStack>
      )}
    </Box>
  );
};
