import { Box, Flex, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { UserContext } from "../../../App";
import { WDProjectModal } from "../../CreateProjectModal/WDProjectModal";
import { createDrawerElementsListObject } from "../drawerElementsListObject";
import { DrawerLinkElement } from "./DrawerLinkElement";

export const ListDrawer: React.FC = () => {
  const userContext = useContext(UserContext);

  return (
    <Flex
      flexDirection="column"
      align="center"
      width="100%"
      justify="center"
      px={2}
      minH="120px"
    >
      <Box pl={3} pr={2} pb={2}>
        <WDProjectModal isCollapsed={true} />
      </Box>
      <VStack
        overflowY="scroll"
        overflowX="hidden"
        __css={{
          direction: "rtl",
          "&::-webkit-scrollbar-track": {
            background: "inherit",
          },
        }}
        h="100%"
        spacing="10px"
        align="center"
        p={1}
        ml={2}
        justifyItems="center"
      >
        {createDrawerElementsListObject(
          userContext.user.roleName === "Admin"
        ).map((dr, i) => {
          return (
            <DrawerLinkElement
              key={"collapsedDrawer" + i}
              url={dr.url}
              Icon={dr.Icon}
              isAdmin={dr.isAdmin}
            />
          );
        })}
      </VStack>
    </Flex>
  );
};
