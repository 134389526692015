import {
  Accordion,
  Box,
  Flex,
  HStack,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../../App";
import { MotionButton } from "../../Utils/MotionComponents";
import { SearchSelectInterface } from "../Common/types";
import { WDSearchSelect } from "../Common/WDSearchSelect";
import { apiClient, authorise } from "../utils/apiClient";
import { CampaignComponent } from "./Campaigns/CampaignComponent";
import { CreateCampaignModal } from "./Campaigns/CreateCampaignModal";
import {
  BackupCampaignInterface,
  CampaignModalInterface,
  ProjectsFromBackend,
} from "./types";
export const BackupLogs: React.FC = () => {
  const colorBackground = useColorModeValue("blue.700", "blue.400");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<BackupCampaignInterface[]>([]);
  const [filteredCampaign, setFilteredCampaign] = useState<
    BackupCampaignInterface[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const errorContext = useContext(ErrorContext);
  const [projectFilter, setProjectFilter] = useState<string>("");
  const [projectsOptions, setProjectsOptions] = useState<
    SearchSelectInterface[]
  >([]);

  const getCampaigns = async () => {
    apiClient
      .get("api/campaigns/get-campaigns", authorise())
      .then((res) => {
        setCampaigns(res.data);
        apiClient
          .get("/api/private/projects/get-campaign", authorise())
          .then((res) => {
            setProjectsOptions([
              {
                label: "All projects",
                value: "",
                color: "neutralGrey",
              },
              ...res.data.map((p: ProjectsFromBackend) => {
                return {
                  label: p.name,
                  value: p.id,
                  color: "neutralGrey",
                };
              }),
            ]);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getCampaigns();
  }, []);
  useEffect(() => {
    setFilteredCampaign(
      projectFilter !== "" && projectFilter !== undefined
        ? campaigns.filter((c) => {
            return c.campaignProject.id === projectFilter;
          })
        : campaigns
    );
  }, [campaigns, projectFilter]);

  return (
    <VStack w={"100%"} h="100%" px={8} align="flex-start">
      <HStack w="100%" alignItems="baseline">
        <Flex h="10%" w="100%" justifyContent={"space-between"} mt={5}>
          {/* <Heading py={8}>Campaigns</Heading>
        <Spacer /> */}
          <Box h={10} w="50%">
            <WDSearchSelect
              placeholder="Filter by project"
              value={projectFilter}
              options={projectsOptions}
              onChange={(val) => {
                setProjectFilter(val as string);
              }}
            />
          </Box>
          <MotionButton
            colorScheme={"blue"}
            bgColor={colorBackground}
            textColor={"white"}
            onClick={() => {
              setIsOpen(true);
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", bounce: 0.4 }}
          >
            New Campaign
          </MotionButton>
        </Flex>
        <CreateCampaignModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSave={(val: CampaignModalInterface) => {
            apiClient
              .post("api/campaigns/add-campaign", val, authorise())
              .then((res) => {
                setCampaigns([...campaigns, res.data]);
                errorContext.createToast("Campaign created!");
              })
              .catch((err) => {
                errorContext.createError([...err.response.data]);
              });
            setIsOpen(false);
          }}
        />
      </HStack>

      {loading ? (
        <Flex h="50vh" w="100%" align="center" justify="center">
          <Spinner size="xl" />
        </Flex>
      ) : filteredCampaign.length ? (
        <Accordion w="100%" minH="fit-content" paddingBottom={10} allowToggle>
          {filteredCampaign?.map((campaign, i) => {
            return (
              <CampaignComponent
                key={campaign?.id + "-" + campaign.campaignTasks.length}
                campaign={campaign}
                setFilteredCampaign={setFilteredCampaign}
                filteredCampaign={filteredCampaign}
                campaigns={campaigns}
                setCampaigns={setCampaigns}
              />
            );
          })}
        </Accordion>
      ) : (
        <Flex h="50vh" w="100%" align="center" justify="center">
          <Text>There is no campaign</Text>
        </Flex>
      )}
    </VStack>
  );
};
