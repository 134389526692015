import { SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { ModalServiceInterface, ServiceErrorInterface } from "../type";
import { BooleanField } from "./BooleanField";
import { NumericField } from "./NumericField";
import { StringField } from "./StringField";

interface DynamicFieldsProps {
  values: ModalServiceInterface;
  setValues: (val: ModalServiceInterface) => void;
  errors: ServiceErrorInterface;
  setErrors: (val: ServiceErrorInterface) => void;
}

export const DynamicFields: React.FC<DynamicFieldsProps> = ({
  values,
  setValues,
  errors,
  setErrors,
}) => {
  return (
    <SimpleGrid
      columns={2}
      spacing={2}
      w="100%"
      //bgColor="gray.200"
      border={values.serviceTypeField.length ? "1px solid" : ""}
      borderColor="blue.200"
      borderRadius={4}
      boxShadow={
        values.serviceTypeField.length
          ? "0px 3px 30px -9px rgba(130,128,130,1)"
          : ""
      }
      p={values.serviceTypeField.length ? 4 : 0}
    >
      {values.serviceTypeField.map((field) => {
        if (field.type === 10) {
          return (
            <StringField
              key={field.name}
              field={field}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
            />
          );
        } else if (field.type === 20) {
          return (
            <NumericField
              key={field.name}
              field={field}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
            />
          );
        } else if (field.type === 30) {
          return (
            <BooleanField
              key={field.name}
              field={field}
              values={values}
              setValues={setValues}
            />
          );
        } else {
          //unknow
          return <Text key={field.name}>???</Text>;
        }
      })}
    </SimpleGrid>
  );
};
