import { Accordion, Flex, Spinner, Text, VStack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ErrorContext } from "../../../App";
import { WDSearchSelect } from "../../Common/WDSearchSelect";
import { apiClient, authorise } from "../../utils/apiClient";
import { ExecutionsInterface } from "../types";
import { ExecutionComponent } from "./ExecutionComponent";
import { CampaignProject } from "./types";

const ITEMS_PER_SECTION = 50;

export const CampaignsExecutions = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingFilterChange, setLoadingFilterChange] = useState<boolean>(true);
  const [executions, setExecutions] = useState<ExecutionsInterface[]>([]);
  const [noMoreData, setNoMoreData] = useState<boolean>(false);
  const { createError } = useContext(ErrorContext);
  const [skip, setSkip] = useState<number>(0);
  const [projects, setProjects] = useState<CampaignProject[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<string>("");

  const getExecutions = (shouldResetSkip?: boolean) => {
    apiClient
      .get(
        `api/campaigns/get-campaign-executions?skip=${
          shouldResetSkip ? 0 : skip
        }&take=${ITEMS_PER_SECTION}${
          selectedProjectId ? `&projectId=${selectedProjectId}` : ""
        }`,
        authorise()
      )
      .then((res) => {
        if (shouldResetSkip) {
          setNoMoreData(false);
          setExecutions(res.data);
        } else {
          setExecutions([...executions, ...res.data]);
        }
        if (res.data.length < 1) {
          setNoMoreData(true);
        }
      })
      .catch((err) => {
        console.log(err);
        createError([...err.response.data]);
      })
      .finally(() => {
        setLoading(false);
        setLoadingFilterChange(false);
      });
  };

  const getCampaignProjects = async () => {
    apiClient
      .get(`/api/private/projects/get-campaign`, authorise())
      .then((res) => {
        setProjects([
          {
            id: "",
            name: "All projects",
          },
          ...res.data.sort((a: CampaignProject, b: CampaignProject) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          }),
        ]);
      })
      .catch((err) => {
        console.log(err);
        createError([...err.response.data]);
      });
  };

  useEffect(() => {
    setLoadingFilterChange(true);
    getExecutions(true);
  }, [selectedProjectId]);

  useEffect(() => {
    getExecutions();
    getCampaignProjects();
  }, []);

  useEffect(() => {
    setSkip(executions.length);
  }, [executions]);

  return (
    <VStack w={"100%"} h="100%" align="center" minH="100vh" px={6} py={4}>
      {/* <HStack w="100%">
        <Heading py={8}>Campaigns Executions</Heading>
        <Spacer />
      </HStack> */}
      <WDSearchSelect
        options={projects.map((p) => ({
          value: p.id,
          label: p.name,
          color: "neutralGrey",
        }))}
        onChange={(val) => setSelectedProjectId(val as string)}
        value={selectedProjectId}
        placeholder={"Filter by project"}
      />
      {loading || loadingFilterChange ? (
        <Flex h="50vh" w="100%" align="center" justify="center">
          <Spinner size="xl" />
        </Flex>
      ) : executions.length ? (
        <Accordion w="100%" allowToggle>
          <InfiniteScroll
            hasMore={!noMoreData}
            next={getExecutions}
            dataLength={executions.length}
            loader={
              <Flex w="100%" align="center" justify="center">
                <Spinner size="xl" />
              </Flex>
            }
            endMessage={
              <Text w="100%" textAlign={"center"} fontWeight="semibold" mb={4}>
                No more executions to show!
              </Text>
            }
            style={{ overflowY: "hidden" }}
          >
            {executions.map((execution, i) => {
              return (
                <ExecutionComponent
                  key={execution.campaignProject.id + i}
                  execution={execution}
                />
              );
            })}
          </InfiniteScroll>
        </Accordion>
      ) : (
        <Flex h="50vh" w="100%" align="center" justify="center">
          <Text>There is no execution</Text>
        </Flex>
      )}
    </VStack>
  );
};
