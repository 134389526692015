import {
  AccordionItem,
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { theme } from "../../../theme";
import { CampaignTaskStatus } from "../ExecutionsStatus";
import { TaskExecution } from "../types";
import { ExecutionGridItem } from "./ExecutionGridItem";

interface ExecutionTaskProps {
  task: TaskExecution;
}

export const ExecutionTask: React.FC<ExecutionTaskProps> = ({ task }) => {
  const selectedBorderColor = useColorModeValue("blue.700", "white");
  // const descriptionColor = useColorModeValue(
  //   theme.colors.blue[800],
  //   theme.colors.darkThemeGrey[100]
  // );
  return (
    <AccordionItem borderWidth="0 0 1px 0" w="100%" pb={3}>
      <Flex alignItems="flex-start" pb={0} flexDirection={"column"}>
        <Grid width={"100%"} templateColumns="repeat(5, 1fr)" gap={8}>
          <ExecutionGridItem
            textAlign="left"
            title={"TASK"}
            content={task.name}
          />

          <ExecutionGridItem
            textAlign="right"
            title="START"
            content={
              task.startedAt
                ? moment(task.startedAt).local().format("DD.MM H:mm")
                : "-"
            }
          />

          <ExecutionGridItem
            ml={5}
            textAlign="right"
            title="END"
            content={
              task.endedAt
                ? moment(task.endedAt).local().format("DD.MM H:mm")
                : "-"
            }
          />

          <ExecutionGridItem
            ml={5}
            textAlign="right"
            title="DURATION"
            content={
              task.duration !== null
                ? Math.floor(task.duration / 60)
                  ? `${Math.floor(task.duration / 60)}h ${
                      task.duration % 60 === 0 ? 1 : task.duration % 60
                    }m`
                  : `${task.duration === 0 ? 1 : task.duration}m`
                : "-"
            }
          />

          <GridItem
            w="100%"
            minW={"150px"}
            display={"flex"}
            alignItems="flex-end"
            justifyContent="center"
          >
            <Box
              outline={"1px solid"}
              outlineColor={selectedBorderColor}
              bg={
                CampaignTaskStatus.filter((s) => {
                  return s.value === task.status;
                })[0]?.color ?? theme.colors.neutralDarkGrey
              }
              p={2}
              w={40}
              minW="fit-content"
              borderRadius={5}
              color="white"
              textAlign="center"
              fontWeight={600}
            >
              {CampaignTaskStatus.filter((s) => {
                return s.value === task.status;
              })[0]?.label ?? "-"}
            </Box>
          </GridItem>
        </Grid>
        <Box w={"100%"}>
          <Text color={"neutralGrey"} noOfLines={2} textOverflow="ellipsis">
            {task.description}
          </Text>
        </Box>
      </Flex>
    </AccordionItem>
  );
};
