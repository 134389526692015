import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { ReactComponent as UsersButtonIcon } from "../../../Assets/Users/UsersButtonIcon.svg";
import { theme } from "../../../theme";
import { WDConfirmDelete } from "../../Common/WDConfirmDelete";
import { CampaignStatus } from "../ExecutionsStatus";
import { CreateTaskModal } from "../Tasks/CreateTaskModal";
import { TaskComponent } from "../Tasks/TaskComponent";
import { BackupCampaignInterface } from "../types";
import { CampaignDetails } from "./CampaignDetails";
import { CreateCampaignModal } from "./CreateCampaignModal";
import { useCCLogic } from "./useCCLogic";

interface CampaignComponentProps {
  campaign: BackupCampaignInterface;
  filteredCampaign: BackupCampaignInterface[];
  setFilteredCampaign: (val: BackupCampaignInterface[]) => void;
  campaigns: BackupCampaignInterface[];
  setCampaigns: (val: BackupCampaignInterface[]) => void;
}

export const CampaignComponent: React.FC<CampaignComponentProps> = ({
  campaign,
  filteredCampaign,
  setFilteredCampaign,
  campaigns,
  setCampaigns,
}) => {
  const allData = useCCLogic(
    campaign,
    filteredCampaign,
    setFilteredCampaign,
    campaigns,
    setCampaigns
  );
  const selectedBorderColor = useColorModeValue("blue.700", "white");

  return (
    <AccordionItem
      key={campaign.id + "-" + campaign.campaignTasks.length}
      marginTop={4}
      borderRadius="8px"
      border={`1px solid ${
        campaign.status
          ? allData.colorPrimary
          : campaign.status === false
          ? theme.colors.danger[400]
          : allData.colorPrimary
      }`}
      boxShadow="0px 6px 12px rgba(0, 0, 0, 0.03)"
      minW="fit-content"
    >
      <HStack p={1}>
        <AccordionButton
          as={Flex}
          minH="80px"
          cursor="pointer"
          alignItems="center"
          pr={0}
        >
          <Grid width={"100%"} templateColumns="repeat(6, 1fr)" gap={4}>
            <GridItem w="100%" minW={"150px"}>
              <CampaignDetails
                textAlign="left"
                title="CAMPAIGN NAME"
                content={campaign.name}
              />
            </GridItem>
            <GridItem w="100%" minW={"150px"}>
              <CampaignDetails
                textAlign="left"
                title="SLUG"
                content={campaign.slug}
              />
            </GridItem>
            <GridItem w="100%" minW={"150px"}>
              <CampaignDetails
                title="LAST START"
                content={
                  campaign.lastStart
                    ? moment(campaign.lastStart).local().format("DD.MM H:mm")
                    : "-"
                }
              />
            </GridItem>
            <GridItem w="100%" minW={"150px"}>
              <CampaignDetails
                title="LAST END"
                content={
                  campaign.lastEnd
                    ? moment(campaign.lastEnd).local().format("DD.MM H:mm")
                    : "-"
                }
              />
            </GridItem>
            <GridItem w="100%" minW={"150px"}>
              <CampaignDetails
                textAlign="right"
                title="LAST DURATION"
                content={
                  campaign.lastDuration !== null
                    ? Math.floor(campaign.lastDuration / 60)
                      ? `${Math.floor(campaign.lastDuration / 60)}h ${
                          campaign.lastDuration % 60 === 0
                            ? 1
                            : campaign.lastDuration % 60
                        }m`
                      : `${
                          campaign.lastDuration === 0
                            ? 1
                            : campaign.lastDuration
                        }m`
                    : "-"
                }
              />
            </GridItem>
            <GridItem w="100%" minW={"150px"}>
              <Box
                marginLeft={4}
                outline={"1px solid"}
                outlineColor={selectedBorderColor}
                bg={
                  CampaignStatus.filter((s) => {
                    return s.value === campaign.lastStatus;
                  })[0]?.color ?? theme.colors.neutralDarkGrey
                }
                p={2}
                w={40}
                minW="fit-content"
                borderRadius={5}
                color="white"
                textAlign="center"
                fontWeight={600}
              >
                {CampaignStatus.filter((s) => {
                  return s.value === campaign.lastStatus;
                })[0]?.label ?? "-"}
              </Box>
            </GridItem>
          </Grid>
        </AccordionButton>

        <Box>
          <Menu>
            <MenuButton
              aria-expanded={true}
              as={IconButton}
              aria-label="Options"
              icon={<UsersButtonIcon stroke={allData.colorStroke} />}
              variant="outline"
            />
            <MenuList bg={allData.colorMenu}>
              <MenuItem
                onClick={() => {
                  allData.setIsOpen(true);
                }}
              >
                Edit campaign
              </MenuItem>
              <CreateCampaignModal
                isOpen={allData.isOpen}
                setIsOpen={allData.setIsOpen}
                onSave={allData.handleEditCampaign}
                initialValues={{
                  name: campaign.name,
                  slug: campaign.slug,
                  projectId: campaign.campaignProject.id,
                  description: campaign.description,
                  timeLimit: campaign.timeLimit,
                }}
              />

              <MenuItem
                onClick={() => {
                  allData.setIsOpenTask(true);
                }}
              >
                Add Task
              </MenuItem>
              <CreateTaskModal
                onSave={allData.createTaskHandler}
                isOpen={allData.isOpenTask}
                setIsOpen={allData.setIsOpenTask}
              />

              <WDConfirmDelete
                handleDelete={allData.handleDeleteCampaign}
                id={campaign.id}
              >
                <MenuItem color="danger.400">Delete campaign</MenuItem>
              </WDConfirmDelete>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
      <AccordionPanel px={10}>
        <Box w={"100%"} marginBottom={4}>
          <Text
            fontWeight={"bold"}
            color={useColorModeValue(
              theme.colors.blue[800],
              theme.colors.darkThemeGrey[100]
            )}
          >
            Description
          </Text>
          <Text color={"neutralGrey"} noOfLines={2} textOverflow="ellipsis">
            {campaign.description}
          </Text>
        </Box>
        <Accordion>
          {campaign.campaignTasks.length ? (
            campaign.campaignTasks.map((task, i) => {
              return (
                <TaskComponent
                  key={task.name + "-" + i}
                  task={task}
                  filteredCampaign={filteredCampaign}
                  setFilteredCampaign={setFilteredCampaign}
                ></TaskComponent>
              );
            })
          ) : (
            <Text
              color={theme.colors.neutralGrey}
              width="100%"
              textAlign="center"
            >
              There are no backup tasks!
            </Text>
          )}
        </Accordion>
      </AccordionPanel>
    </AccordionItem>
  );
};
