import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { ErrorContext, UserContext } from "../../App";
import { ReactComponent as UsersButtonIcon } from "../../Assets/Users/UsersButtonIcon.svg";
import { theme } from "../../theme";
import {
  ProjectInterface,
  ServerInterface,
  ServiceInterface,
} from "../Common/types";
import { WDConfirmDelete } from "../Common/WDConfirmDelete";
import { WDTaskModal } from "../CreateTaskModal/WDTaskModal";
import { OnlineStatus } from "../ProjectsComponent/OnlineStatus/OnlineStatus";
import { apiClient, authorise } from "../utils/apiClient";
import { ConfirmDisableModal } from "./ConfirmDisableModal";
import { ServiceDetails } from "./ServiceDetails";
import { TaskComponent } from "./TaskComponent";
import { ManageService } from "../ManageService2";
import { ModalServiceInterface } from "../ManageService2/type";
import { optionsTypesService } from "../ManageService2/defaultObjects";

interface ServiceComponentProps {
  allServers: ServerInterface[];
  service: ServiceInterface;
  server: ServerInterface;
  setProject: (project: ProjectInterface) => void;
  project: ProjectInterface;
}

export const ServiceComponent: React.FC<ServiceComponentProps> = ({
  service,
  setProject,
  project,
  allServers,
  server,
}) => {
  const errorContext = useContext(ErrorContext);
  const userContext = useContext(UserContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.blue[400]}`
  );
  const colorMenu = useColorModeValue("white", "darkThemeGrey.600");
  const colorPrimary = useColorModeValue(
    theme.colors.blue[100],
    theme.colors.green[800]
  );

  const handleDeleteService = (id: string, onClose: () => void) => {
    apiClient
      .delete(`/api/services/${id}`, authorise())
      .then(() => {
        errorContext.createToast("You have deleted the service succesufully");
        setProject({
          ...project,
          servers: allServers.map((s) => {
            if (s.serverId === server.serverId) {
              return {
                ...s,
                services: server.services.filter(
                  (se) => se.serviceId !== service.serviceId
                ),
              };
            } else return s;
          }),
        });
        onClose();
      })
      .catch((err) => {
        errorContext.createError([err.response.data]);
      });
  };

  const handleDisableService = () => {
    apiClient
      .put(
        `/api/services/switch-active-all/${service.serviceId}`,
        {},
        authorise()
      )
      .then((res) => {
        console.log(res.data);

        setProject({
          ...project,
          servers: allServers.map((s) => {
            if (s.serverId === server.serverId) {
              return {
                ...s,
                services: server.services.map((se) => {
                  if (se.serviceId === service.serviceId) {
                    return { ...service, ...res.data };
                  } else {
                    return se;
                  }
                }),
              };
            } else {
              return s;
            }
          }),
        });
      })
      .catch((err) => {
        errorContext.createError([...err.response.data]);
      });
  };

  const handleEditService = (val: ModalServiceInterface) => {
    apiClient
      .put(
        `/api/services/update/${service.serviceId}`,
        {
          name: val.name,
          ipAddress: val.ipV4,
          iPv6Address: val.ipV6 || "empty",
          isActive: val.enabled,
          portNumber: parseInt(val.port),
          serviceType: service.serviceType,
          serviceTypeField: val.serviceTypeField,
          serviceJob: {
            jobId: val.jobId ?? "",
            isActive: val.enabled,
            startDate: val.startDate,
            recurringTime: val.reccuringTime,
            timeout: val.timeout,
            minimumNotificationLevel: val.minimumNotificationLevel,
            timeoutNotificationLevel: val.timeoutNotificationLevel,
          },
        },
        authorise()
      )
      .then((res) => {
        errorContext.createToast("Service was edited successfully!");
        setProject({
          ...project,
          servers: allServers.map((s) => {
            if (s.serverId === server.serverId) {
              return {
                ...s,
                services: server.services.map((ser) => {
                  if (ser.serviceId === service.serviceId) {
                    return res.data;
                  } else {
                    return ser;
                  }
                }),
              };
            } else {
              return s;
            }
          }),
        });
      })
      .catch((err) => {
        errorContext.createError([...err.response.data]);
        console.log(err);
      });
  };

  return (
    <AccordionItem
      marginTop={4}
      borderRadius="8px"
      border={`1px solid ${
        service.status === 10 ? colorPrimary : "danger.500"
      }`}
      boxShadow="0px 6px 12px rgba(0, 0, 0, 0.03)"
      minW="fit-content"
    >
      <HStack p={1}>
        <AccordionButton
          as={Flex}
          minH="80px"
          cursor="pointer"
          alignItems="center"
          pr={0}
        >
          <Grid width={"100%"} templateColumns="repeat(5, 1fr)" gap={4}>
            <GridItem w="100%">
              <ServiceDetails title="SERVICE NAME" content={service.name} />
            </GridItem>

            <GridItem w="100%">
              <ServiceDetails title="IP ADDRESS" content={service.ipAddress} />
            </GridItem>
            <GridItem w="100%">
              <ServiceDetails
                title="TYPE"
                content={
                  optionsTypesService.filter((opt) => {
                    return opt.value === service.serviceType.toString();
                  })[0]?.display || "asd"
                }
              />
            </GridItem>
            <GridItem w="100%">
              <ServiceDetails title="PORT" content={service.portNumber} />
            </GridItem>
            <GridItem w="100%">
              <ServiceDetails
                title="TASKS RUNNING"
                content={`${
                  service.tasks?.filter((task) => {
                    return task.status !== 40;
                  }).length ?? "0"
                }`}
              />
            </GridItem>
          </Grid>

          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            marginLeft={12}
            w="30%"
          >
            <OnlineStatus
              status={server.status === 40 ? 40 : service.status}
              type={4}
            />
            {project.projectRole === "Designer" ||
            userContext.user.roleName === "Admin" ? (
              <WDTaskModal
                currentService={service}
                setCurrentProject={setProject}
                currentProject={project}
                currentServer={server}
              />
            ) : null}
          </Flex>
        </AccordionButton>
        <Box>
          {project.projectRole === "Designer" ||
          userContext.user.roleName === "Admin" ? (
            <Menu>
              <MenuButton
                aria-expanded={true}
                as={IconButton}
                aria-label="Options"
                icon={<UsersButtonIcon stroke={colorStroke} />}
                variant="outline"
              />
              <MenuList bg={colorMenu}>
                <MenuItem
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  Edit service
                </MenuItem>
                <ManageService
                  onSave={handleEditService}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  serviceId={service.serviceId}
                  project={project}
                  server={server}
                />

                <ConfirmDisableModal
                  disable={server.status === 40}
                  state={!(service.status === 40)}
                  toBeDisabled={service.name}
                  id={service.serviceId}
                  handleDisable={() => {
                    handleDisableService();
                  }}
                >
                  <MenuItem
                    cursor={server.status === 40 ? "not-allowed" : "pointer"}
                  >
                    {!(service.status === 40) ? "Disable" : "Enable"} service
                  </MenuItem>
                </ConfirmDisableModal>

                <WDConfirmDelete
                  handleDelete={handleDeleteService}
                  id={service.serviceId}
                >
                  <MenuItem color="danger.400">Delete service</MenuItem>
                </WDConfirmDelete>
              </MenuList>
            </Menu>
          ) : null}
        </Box>
      </HStack>
      <AccordionPanel px={10}>
        <Text
          color={useColorModeValue("neutralGrey", "darkThemeGrey.100")}
          fontWeight={600}
        >
          Service's Tasks
        </Text>

        {service.tasks?.length ? (
          <Accordion marginTop={5}>
            {service.tasks.map((task) => {
              return (
                <TaskComponent
                  key={task.id}
                  task={task}
                  service={service}
                  currentProject={project}
                  setCurrentProject={setProject}
                  currentServer={server}
                />
              );
            })}
          </Accordion>
        ) : (
          <Text
            color={theme.colors.neutralGrey}
            width="100%"
            textAlign="center"
          >
            There are no tasks!
          </Text>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};
