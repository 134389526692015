import { useColorModeValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../../../App";
import {
  requiredField,
  validatorNumericField,
} from "../../../Utils/ValidatorsForInput";
import { apiClient, authorise } from "../../utils/apiClient";
import {
  CampaignModalErrorInterface,
  CampaignModalInterface,
  ProjectsFromBackend,
  ProjectsOptionsInterface,
} from "../types";

export const useCCModalLogic = (
  setIsOpen: (val: boolean) => void,
  onSave: (val: CampaignModalInterface) => void,
  initialValues?: CampaignModalInterface
) => {
  const errorContext = useContext(ErrorContext);
  const colorPrimary = useColorModeValue("blue.700", "blue.400");
  const colorSecondary = useColorModeValue("blue.800", "darkThemeGrey.100");
  const [loading, setLoading] = useState<boolean>(true);
  const [projectsOptions, setProjectsOptions] = useState<
    ProjectsOptionsInterface[]
  >([]);

  const [value, setValue] = useState<CampaignModalInterface>(
    initialValues ?? {
      name: "",
      slug: "",
      projectId: "",
      description: "",
      timeLimit: 15,
    }
  );

  const [error, setError] = useState<CampaignModalErrorInterface>({
    name: "",
    slug: "",
    projectId: "",
    description: "",
    timeLimit: "",
  });

  const onClose = () => {
    setIsOpen(false);
    setError({
      name: "",
      slug: "",
      projectId: "",
      description: "",
      timeLimit: "",
    });
  };

  const onSaveClickHandler = () => {
    if (
      value.name === "" ||
      value.slug === "" ||
      value.projectId === "" ||
      value.description === ""
    ) {
      setError({
        name: requiredField(value.name) ? "This field is requierd!" : "",
        slug: requiredField(value.slug) ? "This field is requierd!" : "",
        projectId: requiredField(value.projectId)
          ? "This field is requierd!"
          : "",
        description: requiredField(value.description)
          ? "This field is requierd!"
          : "",
        timeLimit: requiredField(`${value.timeLimit}`)
          ? "This field is requierd!"
          : "",
      });
    } else {
      onSave(value);
      setValue({
        name: "",
        slug: "",
        projectId: "",
        description: "",
        timeLimit: 15,
      });
      setError({
        name: "",
        slug: "",
        projectId: "",
        description: "",
        timeLimit: "",
      });
    }
  };

  const handleTimeout = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validatorNumericField(e.target.value) === "") {
      if (e.target.value === "") {
        setValue({ ...value, timeLimit: 0 });
      } else {
        setValue({ ...value, timeLimit: parseInt(e.target.value) });
      }
    }
  };

  useEffect(() => {
    apiClient
      .get("/api/private/projects/get-campaign", authorise())
      .then((res) => {
        const sortedProjects: ProjectsOptionsInterface[] = res.data
          .map((p: ProjectsFromBackend) => {
            return {
              label: p.name,
              value: p.id,
            };
          })
          .sort((a: ProjectsOptionsInterface, b: ProjectsOptionsInterface) => {
            return a.label.localeCompare(b.label);
          });

        setProjectsOptions(
          sortedProjects
            .filter((p) => p.color !== "neutralDarkGrey")
            .concat(sortedProjects.filter((p) => p.color === "neutralDarkGrey"))
        );
        setLoading(false);
      })
      .catch((err) => {
        errorContext.createError([]);
      });
  }, []);

  return {
    colorPrimary,
    colorSecondary,
    loading,
    setLoading,
    projectsOptions,
    setProjectsOptions,
    value,
    setValue,
    error,
    setError,
    onClose,
    onSaveClickHandler,
    handleTimeout,
  };
};
