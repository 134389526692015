import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { theme } from "../../../theme";
import { CampaignStatus } from "../ExecutionsStatus";
import { ExecutionsInterface } from "../types";
import { ExecutionGridItem } from "./ExecutionGridItem";
import { ExecutionTask } from "./ExecutionTask";

interface ExecutionComponentProps {
  execution: ExecutionsInterface;
}

export const ExecutionComponent: React.FC<ExecutionComponentProps> = ({
  execution,
}) => {
  const colorPrimary = useColorModeValue(
    theme.colors.blue[100],
    theme.colors.green[800]
  );
  const selectedBorderColor = useColorModeValue("blue.700", "white");

  return (
    <AccordionItem
      my={6}
      borderRadius="8px"
      border={`1px solid ${colorPrimary}`}
      boxShadow="0px 6px 12px rgba(0, 0, 0, 0.03)"
      minW="fit-content"
      pt={4}
    >
      <AccordionButton
        as={Flex}
        minH="80px"
        cursor="pointer"
        flexDirection={"column"}
        alignItems="flex-start"
        w="100%"
      >
        <Box w="100%">
          <Flex alignItems="flex-start" pb={0} flexDirection={"column"}>
            <Grid width={"100%"} templateColumns="repeat(5, 1fr)" gap={8}>
              <ExecutionGridItem
                textAlign="left"
                title={"CAMPAIGN"}
                content={execution.campaignName}
              />

              <ExecutionGridItem
                textAlign="right"
                title="START"
                content={
                  execution.startedAt
                    ? moment(execution.startedAt).local().format("DD.MM H:mm")
                    : "-"
                }
              />

              <ExecutionGridItem
                ml={5}
                textAlign="right"
                title="END"
                content={
                  execution.endedAt
                    ? moment(execution.endedAt).local().format("DD.MM H:mm")
                    : "-"
                }
              />

              <ExecutionGridItem
                ml={5}
                textAlign="right"
                title="DURATION"
                content={
                  execution.duration !== null
                    ? Math.floor(execution.duration / 60)
                      ? `${Math.floor(execution.duration / 60)}h ${
                          execution.duration % 60 === 0
                            ? 1
                            : execution.duration % 60
                        }m`
                      : `${execution.duration === 0 ? 1 : execution.duration}m`
                    : "-"
                }
              />

              <GridItem
                w="100%"
                minW={"150px"}
                display={"flex"}
                alignItems="flex-end"
                justifyContent="center"
              >
                <Box
                  outline={"1px solid"}
                  outlineColor={selectedBorderColor}
                  bg={
                    CampaignStatus.filter((s) => {
                      return s.value === execution.status;
                    })[0]?.color ?? theme.colors.neutralDarkGrey
                  }
                  p={2}
                  w={40}
                  minW="fit-content"
                  borderRadius={5}
                  color="white"
                  textAlign="center"
                  fontWeight={600}
                >
                  {CampaignStatus.filter((s) => {
                    return s.value === execution.status;
                  })[0]?.label ?? "-"}
                </Box>
              </GridItem>
            </Grid>
            <Grid mt={1} w={"100%"} templateColumns="repeat(5, 16%)" gap={4}>
              <Text color={"neutralGrey"} fontWeight={"semibold"}>
                {execution.campaignProject.name}
              </Text>
              <GridItem colSpan={4}>
                <Text
                  color={"neutralGrey"}
                  noOfLines={2}
                  textOverflow="ellipsis"
                >
                  {execution.description}
                </Text>
              </GridItem>
            </Grid>
          </Flex>
        </Box>
      </AccordionButton>
      <AccordionPanel>
        <Accordion
          allowToggle
          w="100%"
          minH="fit-content"
          borderWidth="3px 0 3px 0"
          pt={3}
        >
          {execution.tasks.length ? (
            execution.tasks.map((task, i) => {
              return <ExecutionTask key={task.name + "-" + i} task={task} />;
            })
          ) : (
            <Text
              color={theme.colors.neutralGrey}
              width="100%"
              textAlign="center"
            >
              There are no tasks!
            </Text>
          )}
        </Accordion>
      </AccordionPanel>
    </AccordionItem>
  );
};
