import {
  Box,
  Flex,
  Progress,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ErrorContext } from "../../App";
import {
  ArrowNext,
  ArrowPrev,
} from "../Common/ReactSlicker/CustomArrowsForSlicker";
import {
  SelectedInterval,
  TimeIntervalWrapper,
} from "../Common/TimeIntervalWrapper";
import { ProjectInterface } from "../Common/types";
import { apiClient, authorise } from "../utils/apiClient";
import { CustomTabs } from "./CustomTabs";
import { ProjectCardFooter } from "./ProjectCardFooter";
import { ProjectCardHeader } from "./ProjectCardHeader";
import { Services } from "./Services";

interface ProjectProps {
  project: ProjectInterface;
  deleteProject: (id: string) => void;
  projects: ProjectInterface[];
  setProjects: (val: ProjectInterface[]) => void;
}
export const ProjectCard: React.FC<ProjectProps> = (props) => {
  const [project, setProject] = useState<ProjectInterface>(props.project);
  const useError = useContext(ErrorContext);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [loadingServices, setLoadingServices] = useState<boolean>(true);
  const [viz, setViz] = useState<boolean[]>(
    props.project.servers.map((s, i) => false)
  );
  const [serverId, setServerId] = useState<string>();
  const [selectedInterval, setSelectedInterval] =
    useState<SelectedInterval>("24h");
  const navigate = useNavigate();
  const settings = {
    infinite: false,
    variableWidth: true,

    rows: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 240,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
  };

  const getServerServices = async (serverId: string) => {
    apiClient
      .get(
        `/api/private/projects/v2/my-projects/services/${serverId}`,
        authorise()
      )
      .then((res) => {
        setProject({
          ...project,
          servers: project.servers.map((s) => {
            if (s.serverId === serverId) {
              return {
                ...s,
                services: res.data,
              };
            } else {
              return s;
            }
          }),
        });
        setLoadingServices(false);
      })
      .catch((err) => {
        useError?.createError([err.response]);
        setLoadingServices(false);
      });
  };

  const handleSlick = (index: number) => {
    setTabIndex(index);
  };
  useEffect(() => {
    if (props.project.servers?.length === 1) setTabIndex(0);
  }, [props.project]);

  useEffect(() => {
    setSelectedInterval("24h");
    props.project.servers.forEach((server, i) => {
      if (i === tabIndex) {
        setServerId(server.serverId);
      }
    });
  }, [tabIndex]);

  return (
    <VStack w={"100%"} pb={10} spacing={0}>
      <ProjectCardHeader
        projectRole={project.projectRole}
        title={project.name}
        iconId={project.icon}
        status={project.status}
      />
      <Tabs
        w="100%"
        h="100%"
        onChange={(index) => setTabIndex(index)}
        index={tabIndex}
      >
        <TabList justifyItems="flex-start" pl={4} pr={4}>
          <Box w="100%" px={4}>
            <Slider {...settings} afterChange={handleSlick}>
              {project.servers.map((ser, i) => {
                return (
                  <Box
                    key={ser.serverId + ser.name}
                    mb={1}
                    onClick={() => {
                      if (!viz[tabIndex]) {
                        setViz(
                          viz.map((val, i) => {
                            if (i === tabIndex) {
                              return true;
                            } else {
                              return val;
                            }
                          })
                        );
                        setLoadingServices(true);
                        getServerServices(ser.serverId);
                      }
                    }}
                  >
                    <CustomTabs
                      index={i}
                      title={ser.name}
                      status={ser.status}
                    />
                  </Box>
                );
              })}
            </Slider>
          </Box>
        </TabList>

        <TabPanels h="100%">
          {project.servers.map((ser, i) => {
            return (
              <TabPanel
                fontWeight={600}
                fontSize={20}
                key={ser.serverId + i}
                h="100%"
                maxH="100%"
                pt={0}
              >
                <TimeIntervalWrapper
                  isConfigured={ser.isConfigured}
                  title="Resources overview"
                  hasTitleIcon
                  onResourcePage={() => {
                    navigate(
                      `../dashboard/projects/${project.projectId}/resources/${ser.serverId}`
                    );
                  }}
                  onIntervalChange={(selectedInterval: SelectedInterval) => {
                    setSelectedInterval(selectedInterval);
                  }}
                  initialInterval={selectedInterval}
                >
                  <Flex direction="row" justifyContent={"space-between"} my={1}>
                    <Flex direction={"column"}>
                      <Text fontSize={"md"} color="gray.500">
                        CPU Usage
                      </Text>
                      <Text fontSize={"md"}>
                        {selectedInterval === "24h"
                          ? `${ser.usages[0]?.cpu.toFixed(2)}%`
                          : selectedInterval === "7d"
                          ? `${ser.usages[1]?.cpu.toFixed(2)}%`
                          : `${ser.usages[2]?.cpu.toFixed(2)}%`}
                      </Text>
                    </Flex>
                    <Flex direction={"column"}>
                      <Text fontSize={"md"} color="gray.500">
                        Memory Usage
                      </Text>
                      <Text fontSize={"md"}>
                        {selectedInterval === "24h"
                          ? `${ser.usages[0]?.mem.toFixed(2)}%`
                          : selectedInterval === "7d"
                          ? `${ser.usages[1]?.mem.toFixed(2)}%`
                          : `${ser.usages[2]?.mem.toFixed(2)}%`}
                      </Text>
                    </Flex>
                    <Flex direction={"column"}>
                      <Text fontSize={"md"} color="gray.500">
                        Disk Usage
                      </Text>
                      <Text fontSize={"md"}>
                        {selectedInterval === "24h"
                          ? `${ser.usages[0]?.disk.toFixed(2)}%`
                          : selectedInterval === "7d"
                          ? `${ser.usages[1]?.disk.toFixed(2)}%`
                          : `${ser.usages[2]?.disk.toFixed(2)}%`}
                      </Text>
                    </Flex>
                  </Flex>
                </TimeIntervalWrapper>
                {tabIndex !== 0 && loadingServices ? (
                  <Progress size="xs" isIndeterminate />
                ) : (
                  <Services service={ser.services} />
                )}
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
      <ProjectCardFooter
        project={props.project}
        deleteProject={props.deleteProject}
        projects={props.projects}
        setProjects={props.setProjects}
        serverId={serverId as string}
      />
    </VStack>
  );
};
