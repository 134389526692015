import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MotionButton } from "../../../Utils/MotionComponents";
import { requiredField } from "../../../Utils/ValidatorsForInput";
import { WDInput } from "../../Common/WDInput";
import { TaskModalInterface } from "../types";

interface CreateTaskModalProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  initialValues?: TaskModalInterface;
  onSave: (val: TaskModalInterface) => void;
}

export const CreateTaskModal: React.FC<CreateTaskModalProps> = ({
  isOpen,
  setIsOpen,
  initialValues,
  onSave,
}) => {
  const colorPrimary = useColorModeValue("blue.700", "blue.400");
  const colorSecondary = useColorModeValue("blue.800", "darkThemeGrey.100");
  const [value, setValue] = useState<TaskModalInterface>(
    initialValues ?? { name: "", slug: "" }
  );
  const [error, setError] = useState<TaskModalInterface>({
    name: "",
    slug: "",
  });

  const onClose = () => {
    setIsOpen(false);
  };

  const onSaveClickHandler = () => {
    if (value.name === "" || value.slug === "") {
      setError({
        name: requiredField(value.name) ? "This field is requierd!" : "",
        slug: requiredField(value.slug) ? "This field is requierd!" : "",
      });
    } else {
      onSave(value);
      setError({ name: "", slug: "" });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior={"inside"}
      isCentered
      closeOnOverlayClick={false}
      size={"xl"}
      motionPreset="slideInRight"
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalHeader
          borderRadius={"8px 8px 0px 0px"}
          backgroundColor={useColorModeValue("blue.100", "darkThemeGrey.600")}
          color={colorSecondary}
        >
          New Task
        </ModalHeader>
        <ModalCloseButton
          onClick={onClose}
          margin={"5px 8px 0px 0px"}
          borderWidth={2}
          borderRadius={5}
          color={colorSecondary}
          borderColor={colorSecondary}
        />
        <ModalBody
          padding={5}
          bg={useColorModeValue("white", "darkThemeGrey.700")}
        >
          <Flex direction="column" w="100%" justify="center" align="center">
            <Box width={"60%"}>
              <Flex>
                <Text>Task's name</Text>
                <Text color="danger.500">*</Text>
              </Flex>
              <WDInput
                value={value.name}
                error={error.name}
                onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                  if (val.target.value !== "") {
                    setError({ ...error, name: "" });
                  }
                  setValue({ ...value, name: val.target.value });
                }}
                placeholder="Add task's name here.."
              ></WDInput>
            </Box>
            <Box width={"60%"}>
              <Flex>
                <Text>Slug</Text>
                <Text color="danger.500">*</Text>
              </Flex>
              <WDInput
                value={value.slug}
                error={error.slug}
                onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                  if (val.target.value !== "") {
                    setError({ ...error, slug: "" });
                  }
                  setValue({ ...value, slug: val.target.value });
                }}
                placeholder="Add task's slug here.."
              ></WDInput>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter bg={useColorModeValue("white", "darkThemeGrey.700")}>
          <MotionButton
            tabIndex={1}
            backgroundColor={colorPrimary}
            colorScheme={useColorModeValue("blue", "blue")}
            color={"white"}
            fontSize={"sm"}
            width={"30%"}
            mr={3}
            onClick={onSaveClickHandler}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", bounce: 0.4 }}
          >
            Save
          </MotionButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
