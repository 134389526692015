import { theme } from "../theme";

export const customNotifications = [
  {
    value: "0",
    display: "Information",
    color: theme.colors.notificationsColor.green,
  },
  {
    value: "10",
    display: "Yellow Alert",
    color: theme.colors.notificationsColor.yellow,
  },
  {
    value: "20",
    display: "Red Alert",
    color: theme.colors.notificationsColor.red,
  },
  {
    value: "30",
    display: "Critical",
    color: theme.colors.notificationsColor.purple,
  },
];
export const statusEnum = [
  {
    value: "0",
    display: "Unknown",
    color: theme.colors.neutralDarkGrey,
  },
  {
    value: "10",
    display: "Online",
    color: theme.colors.green[600],
  },
  {
    value: "20",
    display: "Offline",
    color: theme.colors.danger[500],
  },
  {
    value: "30",
    display: "Unexpected",
    color: theme.colors.warning[500],
  },
  {
    value: "40",
    display: "Disabled",
    color: theme.colors.gray[400],
  },
  {
    value: "50",
    display: "Deploy",
    color: theme.colors.blue[600],
  },
];

export const ResourceStatusEnum = [
  {
    value: 0,
    color: theme.colors.neutralDarkGrey,
  },
  {
    value: 10,
    color: theme.colors.green[600],
  },
  {
    value: 20,
    color: theme.colors.warning[500],
  },
  {
    value: 30,
    color: theme.colors.danger[500],
  },
  {
    value: 40,
    color: theme.colors.notificationsColor.purple,
  },
];

export const statusCodeEnum = [
  {
    value: "10",
    display: "GET",
  },
  {
    value: "20",
    display: "POST",
  },
  {
    value: "30",
    display: "PUT",
  },
  {
    value: "40",
    display: "PATCH",
  },
  {
    value: "50",
    display: "OPTIONS",
  },
  {
    value: "60",
    display: "DELETE",
  },
];
