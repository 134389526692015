import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Flex,
  Progress,
  Tooltip,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { ErrorContext } from "../../App";
import { ServiceInterface } from "../Common/types";
import { optionsTypesService } from "../ManageService2/defaultObjects";
import { apiClient, authorise } from "../utils/apiClient";
import { OnlineStatus } from "./OnlineStatus/OnlineStatus";
import { Task } from "./Task";
interface ServiceProps {
  service: ServiceInterface[];
}
export const Services: React.FC<ServiceProps> = (props) => {
  const useError = useContext(ErrorContext);
  const [currentServiceId, setCurrentServiceId] = useState<string>("");
  const [loadingTasks, setLoadingTasks] = useState<boolean>(false);
  const [service, setService] = useState<ServiceInterface[]>(props.service);
  const [viz, setViz] = useState<boolean[]>(props.service.map((s) => false));

  const getServiceTasks = async (serviceId: string) => {
    apiClient
      .get(
        `/api/private/projects/v2/my-projects/tasks/${serviceId}`,
        authorise()
      )
      .then((res) => {
        setService(
          service.map((s) => {
            if (s.serviceId === serviceId) {
              return {
                ...s,
                tasks: res.data,
              };
            } else {
              return s;
            }
          })
        );
        setLoadingTasks(false);
      })
      .catch((err) => {
        useError.createError([err.response]);
        setLoadingTasks(false);
      });
  };

  return (
    <Accordion h="100%" allowToggle>
      {service.length === 0 && (
        <AccordionItem w="100%" p={0}>
          <Box
            textAlign={"center"}
            w="100%"
            fontSize={12}
            fontWeight="normal"
            color="gray.500"
            h="30px"
          >
            There are no services for this server.
          </Box>
        </AccordionItem>
      )}
      {service.map((ser, i) => {
        return (
          <AccordionItem
            key={ser.serviceId}
            border="0px solid"
            borderBottom="1px solid"
            borderColor="neutralGrey"
          >
            <AccordionButton
              w="100%"
              onClick={() => {
                if (!viz[i]) {
                  setViz(
                    viz.map((val, index) => {
                      if (i === index) {
                        return true;
                      } else {
                        return val;
                      }
                    })
                  );
                  setLoadingTasks(true);
                  setCurrentServiceId(ser.serviceId);
                  getServiceTasks(ser.serviceId);
                }
              }}
            >
              <Flex
                justifyContent="space-between"
                w="100%"
                fontWeight={600}
                lineHeight={8}
                fontSize={14}
                gap={4}
              >
                <Box
                  pl={4}
                  textAlign="left"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  w="120px"
                  maxW="30%"
                >
                  {ser.name}
                </Box>

                <Box
                  textAlign={"right"}
                  overflow="hidden"
                  w="300px"
                  maxW="30%"
                  textOverflow={"ellipsis"}
                  whiteSpace="nowrap"
                >
                  {ser.ipAddress.length <= 30 ? (
                    ser.ipAddress
                  ) : (
                    <Tooltip label={ser.ipAddress}>{ser.ipAddress}</Tooltip>
                  )}
                </Box>

                <Box textAlign={"right"} pr={8} maxW="20%">
                  {ser.portNumber}
                </Box>

                <Box textAlign={"left"} maxW="20%">
                  {ser.serviceType.toString() === "0"
                    ? "Generic"
                    : optionsTypesService.filter((opt) => {
                        return opt.value === ser.serviceType.toString();
                      })[0]?.display}
                </Box>
                <OnlineStatus status={ser.status} type={2} />
              </Flex>
            </AccordionButton>

            {loadingTasks ? (
              currentServiceId === ser.serviceId && (
                <Progress size="xs" isIndeterminate />
              )
            ) : (
              <Task tasks={ser.tasks} serviceId={ser.serviceId} />
            )}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
