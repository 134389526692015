import { useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { requiredField } from "../../Utils/ValidatorsForInput";
import { ServerInterface } from "../Common/types";
import { apiClient, authorise } from "../utils/apiClient";
import { defaultServiceError, defaultServiceValues } from "./defaultObjects";
import { ModalServiceInterface, ServiceErrorInterface } from "./type";

export const useManageService = (
  isOpen: boolean,
  setIsOpen: (val: boolean) => void,
  onSave: (val: ModalServiceInterface) => void,
  server: ServerInterface,
  serviceId?: string,
  
) => {
  const colorHeader = useColorModeValue("blue.800", "darkThemeGrey.100");
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [values, setValues] =
    useState<ModalServiceInterface>({
      ...defaultServiceValues,
      ipV4: server?.ipAddress ?? ""
    });
  const [errors, setErrors] =
    useState<ServiceErrorInterface>(defaultServiceError);

  const [initialValuesEdit, setInitialValuesEdit] =
    useState<ModalServiceInterface>(defaultServiceValues);

  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);

  const resetModal = () => {
    setStep(0);
    setValues({
      ...defaultServiceValues,
      ipV4: server?.ipAddress ?? ""
    });
    setErrors(defaultServiceError);
  };

  const closeModal = () => {
    resetModal();
    setIsOpen(false);
  };

  const convertResFromBackend = (res: any) => {
    return {
      name: res.data.name,
      ipV4: res.data.ipAddress,
      ipV6: res.data.iPv6Address === "empty" ? "" : res.data.iPv6Address,
      port: res.data.portNumber.toString(),
      type: {
        id: res.data.serviceType.toString(),
        name: "",
      },
      serviceTypeField: res.data.serviceTypeField,
      enabled: res.data.job.isActive,
      startDate: res.data.job.startDate,
      reccuringTime: res.data.job.recurringTime,
      timeout: res.data.job.timeout,
      minimumNotificationLevel: res.data.job.minimumNotificationLevel,
      timeoutNotificationLevel: res.data.job.timeoutNotificationLevel,
      jobId: res.data.job.jobId,
    };
  };

  const handleFinalClick = () => {
    if (step === 0) {
      if (
        values.name === "" ||
        values.ipV4 === "" ||
        values.port === "" ||
        values.type.id === "" ||
        values.serviceTypeField.filter(
          (field) => field.value === "" && field.name !== "query" && field.name !== "database" && field.name !== "certificate"
        ).length
      ) {
        setErrors({
          ...errors,
          name: requiredField(values.name),
          ipV4: requiredField(values.ipV4),
          port: requiredField(values.port),
          type: requiredField(values.type.id),
          serviceTypeField: values.serviceTypeField.map((val) => {
            return {
              name: val.name,
              message:
                val.name === "query" || val.name === "database" || val.name === "certificate"
                  ? ""
                  : requiredField(val.value.toString()),
            };
          }),
        });
      } else {
        setStep(1);
      }
    } else {
      if (values.minimumNotificationLevel === 5) {
        setErrors({
          ...errors,
          minimumNotificationLevel:
            values.minimumNotificationLevel === 5
              ? "This field is requierd!"
              : "",
        });
      } else {
        onSave(values);

        closeModal();
      }
    }
  };

  const checkForChanges = () => {
    if (
      JSON.stringify(values) !==
      JSON.stringify(serviceId ? initialValuesEdit : {
        ...defaultServiceValues,
        ipV4: server?.ipAddress ?? ""
      })
    ) {
      setIsOpenConfirm(true);
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    if (serviceId && isOpen) {
      setLoading(true);
      apiClient
        .get(`/api/services/${serviceId}`, authorise())
        .then((res) => {
          setValues(convertResFromBackend(res));
          setInitialValuesEdit(convertResFromBackend(res));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          closeModal();
        });
    } else if(isOpen) {
      setValues({
        ...defaultServiceValues,
        ipV4: server?.ipAddress ?? ""
      })
    }
  }, [isOpen]);

  return {
    values,
    setValues,
    errors,
    setErrors,
    colorHeader,
    checkForChanges,
    convertResFromBackend,
    handleFinalClick,
    resetModal,
    closeModal,
    isOpenConfirm,
    setIsOpenConfirm,
    initialValuesEdit,
    loading,
    setLoading,
    step,
    setStep,
  };
};
