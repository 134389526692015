import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  IconButton,
  Spacer,
  useColorMode,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { ReactComponent as MenuIcon } from "../../Assets/NavBar/MenuIcon.svg";
import { theme } from "../../theme";
import { NavBarHeight } from "../Dashboard/DashboardWrapper/DashboardWrapper";
interface OpenDrawerInterface {
  openDrawer: () => void;
  active: boolean;
}
export const NavBar: React.FC<OpenDrawerInterface> = ({
  openDrawer,
  active,
}) => {
  const { colorMode, toggleColorMode } = useColorMode();

  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.darkThemeGrey[100]}`
  );
  return (
    <Box w={`calc(100% - ${active ? 280 : 80}px)`}>
      <VStack
        h={NavBarHeight}
        w="100%"
        bg={useColorModeValue("blue.100", "darkThemeGrey.600")}
      >
        <HStack
          h="100%"
          w="100%"
          borderBottomWidth={1}
          borderColor={useColorModeValue("neutralGrey", "darkThemeGrey.600")}
          align="center"
        >
          <Box ml={2}>
            <IconButton
              aria-label="Menu"
              icon={<MenuIcon stroke={colorStroke} />}
              onClick={() => {
                openDrawer();
              }}
              bg={useColorModeValue("blue.100", "darkThemeGrey.600")}
            />
          </Box>
          <Spacer />

          <Box onClick={toggleColorMode}>
            <IconButton
              aria-label="Notification Icon"
              icon={
                colorMode === "light" ? (
                  <MoonIcon color="blue.800" boxSize={6} />
                ) : (
                  <SunIcon color="darkThemeGrey.100" boxSize={6} />
                )
              }
              mr={4}
            />
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};
