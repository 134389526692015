import {
  AccordionItem,
  Box,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { ErrorContext } from "../../../App";
import { ReactComponent as UsersButtonIcon } from "../../../Assets/Users/UsersButtonIcon.svg";
import { theme } from "../../../theme";
import { WDConfirmDelete } from "../../Common/WDConfirmDelete";
import { apiClient, authorise } from "../../utils/apiClient";
import {
  BackupCampaignInterface,
  CampaignTaskInterface,
  TaskModalInterface,
} from "../types";
import { CreateTaskModal } from "./CreateTaskModal";
import { TaskDetails } from "./TaskDetails";

interface TaskComponentProps {
  task: CampaignTaskInterface;
  filteredCampaign: BackupCampaignInterface[];
  setFilteredCampaign: (val: BackupCampaignInterface[]) => void;
}

export const TaskComponent: React.FC<TaskComponentProps> = ({
  task,
  filteredCampaign,
  setFilteredCampaign,
}) => {
  const errorContext = useContext(ErrorContext);
  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.blue[400]}`
  );
  const colorMenu = useColorModeValue("white", "darkThemeGrey.600");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleEditTask = (val: TaskModalInterface) => {
    apiClient
      .put(
        "/api/campaigns/modify-campaign-task",
        { id: task.id, name: val.name, slug: val.slug },
        authorise()
      )
      .then((res) => {
        setFilteredCampaign(
          filteredCampaign.map((c) => {
            if (c.id === task.campaignId) {
              return {
                ...c,
                campaignTasks: c.campaignTasks.map((t) => {
                  if (t.id === task.id) {
                    return {
                      campaignId: task.campaignId,
                      ...res.data,
                    };
                  } else {
                    return t;
                  }
                }),
              };
            } else {
              return c;
            }
          })
        );
        errorContext.createToast("Task edited succesufully");
      })
      .catch((err) => {
        errorContext.createError([...err.response.data]);
      });
    setIsOpen(false);
  };

  const handleDeleteTask = (id: string, onClose?: () => void) => {
    apiClient
      .delete(`/api/campaigns/delete-campaign-task/${id}`, authorise())
      .then(() => {
        setFilteredCampaign(
          filteredCampaign.map((c) => {
            if (c.id === task.campaignId) {
              return {
                ...c,
                campaignTasks: c.campaignTasks.filter((t) => {
                  return t.id !== id;
                }),
              };
            } else {
              return c;
            }
          })
        );
        errorContext.createToast("Task deleted succesufully");
        onClose?.();
      })
      .catch((err) => {
        errorContext.createError([...err.response.data]);
      });
  };

  return (
    <AccordionItem
      key={task.id + "-" + task.name}
      marginBottom={5}
      borderWidth="0 0 1px 0"
      py={2}
    >
      <Flex justify="space-between">
        <Grid width={"100%"} templateColumns="repeat(2, 1fr)" gap={2}>
          <GridItem w="100%" minW={"200px"}>
            <TaskDetails
              textAlign="left"
              title="TASK NAME"
              content={task.name}
            />
          </GridItem>

          <GridItem w="100%" minW={"200px"}>
            <TaskDetails textAlign="left" title="SLUG" content={task.slug} />
          </GridItem>
        </Grid>

        <Box>
          <Menu>
            <MenuButton
              width="80%"
              aria-expanded={true}
              as={IconButton}
              aria-label="Options"
              icon={<UsersButtonIcon stroke={colorStroke} />}
              variant="outline"
            />
            <MenuList bg={colorMenu}>
              <MenuItem
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Edit Task
              </MenuItem>
              <CreateTaskModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onSave={handleEditTask}
                initialValues={{ name: task.name, slug: task.slug }}
              />

              <WDConfirmDelete handleDelete={handleDeleteTask} id={task.id}>
                <MenuItem color="danger.400">Delete task</MenuItem>
              </WDConfirmDelete>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </AccordionItem>
  );
};
