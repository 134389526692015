import { GridItem, ResponsiveValue, Text } from "@chakra-ui/react";
import { TextAlign } from "chart.js";
import React from "react";

interface CampaignDetailsProps {
  title: string;
  content: string;
  ml?: number;
  textAlign?: ResponsiveValue<TextAlign>;
}

export const ExecutionGridItem: React.FC<CampaignDetailsProps> = ({
  title,
  content,
  ml,
  textAlign,
}) => {
  return (
    <GridItem w="100%" minW={"150px"} ml={ml} textAlign={textAlign}>
      <Text fontWeight="600" color="neutralGrey" fontSize={10}>
        {title}
      </Text>
      <Text fontWeight={500}>{content ?? "-"}</Text>
    </GridItem>
  );
};
