import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorContext } from "../../../App";
import { SelectedInterval } from "../../Common/TimeIntervalWrapper";
import { apiClient, authorise } from "../../utils/apiClient";
import {
  ProjectResourceOverviewInterface,
  ResourceOverviewContextInterface,
  ServerInterface,
  ServerMetricsInterface,
} from "./types";
import { recordNameArray, recordNameEnum, ResourceName } from "./utils";

export const useResourceOverview = (): ResourceOverviewContextInterface => {
  const { projectId, serverId } = useParams();

  const errorContext = useContext(ErrorContext);
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState<number | undefined>(undefined);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [loadingGraphs, setLoadingGraphs] = useState<boolean>(true);

  const [serverMetrics, setServerMetrics] =
    useState<ServerMetricsInterface | null>(null);
  const [project, setProject] = useState<ProjectResourceOverviewInterface>(
    {} as ProjectResourceOverviewInterface
  );

  const setInitialProject = async () => {
    var projectHeaderResponse: any;

    setLoadingPage(true);
    setLoadingGraphs(true);
    await apiClient
      .get(`/api/resources/server-info/${serverId}`, authorise())
      .then((res) => {
        setServerMetrics({
          ...res.data,
          cpuDetails: JSON.parse(res.data.cpuDetails),
        });
      })
      .catch((err) => {
        errorContext.createError(err.response);
      });
    projectHeaderResponse = apiClient.get(
      `/api/private/projects/resources-overview-header/${projectId}`,
      authorise()
    );

    var graphsOverviewResponses: any[] = [];
    recordNameArray.map(async (r, i) => {
      graphsOverviewResponses[i] = apiClient.get(
        `/api/resources/graphs/${r.toLowerCase()}/${serverId}/1`,
        authorise()
      );
    }); //MAP THROUGH RESOURCE NAME AND CREATE RESOURCE

    axios
      .all([projectHeaderResponse, ...graphsOverviewResponses])
      .then(
        axios.spread((...responses) => {
          var graphsOverview: any[] = [];
          responses.forEach((r, i) => {
            if (i >= 1)
              graphsOverview[i] = {
                id: recordNameEnum[r.data.areaChart.resourceName],
                title: `${
                  recordNameEnum[r.data.areaChart.resourceName]
                } Overview`,
                currentPeriod: "24h",
                ...r.data,
              };
          });
          setTabIndex(
            responses[0].data.servers.findIndex(
              (s: ServerInterface, i: number) => s.serverId === serverId
            )
          );
          setProject({
            ...responses[0].data,
            graphs: graphsOverview.sort((a, b) => {
              return a.title.localeCompare(b.title);
            }),
          });
          setLoadingPage(false);
          setTimeout(() => {
            setLoadingGraphs(false);
          }, 1000);
        })
      )
      .catch((errors) => {
        errorContext.createError([""]);
      });
  };

  const onIntervalChange = async (
    id: ResourceName | string,
    val: SelectedInterval
  ) => {
    if (val !== project.graphs.filter((g) => g.id === id)[0].currentPeriod)
      await apiClient
        .get(
          `/api/resources/graphs/${id}/${serverId}/${
            val === "24h" ? 1 : val === "7d" ? 7 : 30
          }`,
          authorise()
        )
        .then((res) => {
          setProject({
            ...project,
            graphs: project.graphs.map((g) => {
              if (g.id === id) {
                return {
                  ...g,
                  currentPeriod: val,
                  areaChart: res.data.areaChart,
                };
              } else return g;
            }),
          });
        })
        .catch((err) => {
          errorContext.createError(err.response.data);
        });
  };
  const onTabChange = (index: number) => {
    if (project.servers[index].isConfigured)
      navigate(
        `../dashboard/projects/${projectId}/resources/${project.servers[index].serverId}`
      );
    else
      navigate(
        `../dashboard/projects/${projectId}/${project.servers[index].serverId}`
      );
  };

  return {
    loadingGraphs,
    onTabChange,
    project,
    tabIndex,
    setInitialProject,
    loadingPage,
    onIntervalChange,
    serverMetrics,
  };
};
