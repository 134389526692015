import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useColorModeValue,
  VStack,
  Text,
  Switch,
} from "@chakra-ui/react";
import React from "react";
import { ProjectInterface } from "../Common/types";
import { StepBar } from "./StepBar";
import { StepOnePage } from "./StepOnePage";
import { StepTwoPage } from "./StepTwoPage";
import { CustomSpinner } from "../Common/Spinner";
import { MotionButton } from "../../Utils/MotionComponents";
import { useProjectModalLogic } from "./useProjectModalLogic";

export interface errorMessageInterface {
  name: string;
  description: string;
}

interface WDProjectModalProps {
  isCollapsed?: boolean;
  isOnPage?: boolean;
  onSucces?: (val: ProjectInterface) => void;
}

export const WDProjectModal: React.FC<WDProjectModalProps> = ({
  isCollapsed,
  isOnPage,
  onSucces,
}) => {
  const allData = useProjectModalLogic(isOnPage, onSucces);

  return (
    <>
      <MotionButton
        onClick={allData.onOpen}
        color="white"
        colorScheme={"blue"}
        backgroundColor={allData.colorPrimary}
        borderRadius={8}
        fontSize={16}
        fontFamily="Montserrat"
        fontWeight={600}
        lineHeight={5}
        width="90%"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: "spring", bounce: 0.4 }}
      >
        {isCollapsed ? "+" : "+ New Project"}
      </MotionButton>

      <Modal
        scrollBehavior={"inside"}
        isCentered
        closeOnOverlayClick={false}
        isOpen={allData.isOpen}
        onClose={allData.onCloseBigModal}
        size={"3xl"}
        motionPreset="slideInRight"
      >
        <ModalOverlay backdropFilter="blur(10px)">
          <ModalContent top={"5vh"} maxBlockSize={"l"}>
            <ModalHeader
              borderRadius={"8px 8px 0px 0px"}
              backgroundColor={useColorModeValue(
                "blue.100",
                "darkThemeGrey.600"
              )}
              color={allData.colorSecondary}
            >
              {allData.step === 0 ? "New project" : "Assign users to project"}
            </ModalHeader>

            <ModalCloseButton
              onClick={allData.onCloseBigModal}
              margin={"5px 8px 0px 0px"}
              borderWidth={2}
              borderRadius={5}
              color={allData.colorSecondary}
              borderColor={allData.colorSecondary}
            />
            <ModalBody
              padding={5}
              bg={useColorModeValue("white", "darkThemeGrey.700")}
            >
              <StepBar
                setErrorMessage={allData.setErrorMessage}
                value={allData.modalData}
                step={allData.step}
                setStep={allData.setStep}
                setModalData={allData.setModalData}
              />
              {allData.step === 0 ? (
                <StepOnePage
                  error={allData.errorMessage}
                  changeModalDataHandler={allData.changeModalDataHandler}
                  value={allData.modalData}
                />
              ) : allData.weHaveUsers && allData.weHaveRoles ? (
                <StepTwoPage
                  roles={allData.roles}
                  users={allData.users}
                  values={allData.modalData}
                  changeModalDataHandler={allData.changeModalDataHandler}
                  onDelete={allData.deleteUser}
                />
              ) : (
                <CustomSpinner />
              )}
            </ModalBody>
            <ModalFooter bg={useColorModeValue("white", "darkThemeGrey.700")}>
              {allData.step === 0 && (
                <Flex width="50%" align="center" justify="center">
                  <Text marginRight={2}>Is for Campaign?</Text>
                  <Switch
                    size="md"
                    isChecked={allData.isForCampaign}
                    onChange={() => {
                      allData.setIsForCampaign(!allData.isForCampaign);
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                  />
                </Flex>
              )}
              {allData.step === 1 && (
                <Button
                  onClick={allData.addAnotherUser}
                  color={allData.colorPrimary}
                  variant={"link"}
                  mr={"15em"}
                  fontSize="sm"
                >
                  +Add another user
                </Button>
              )}
              <MotionButton
                tabIndex={1}
                backgroundColor={allData.colorPrimary}
                colorScheme={useColorModeValue("blue", "blue")}
                color={"white"}
                fontSize={"sm"}
                width={"50%"}
                mr={3}
                onClick={allData.onClickHandler}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", bounce: 0.4 }}
              >
                {allData.step === 0 ? "Next" : "Save"}
              </MotionButton>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
      <Modal
        isOpen={allData.isOpenConfirm} //isOpenConfirm
        onClose={allData.onCloseConfirm} //onCloseConfirm
        isCentered
        blockScrollOnMount={false}
      >
        <ModalCloseButton
          onClick={allData.onCloseConfirm} //onCloseConfirm
          margin={"5px 8px 0px 0px"}
          color="blue.800"
          borderWidth={2}
          borderRadius={5}
          borderColor={"blue.800"}
        />
        <ModalOverlay backdropFilter="blur(10px)" backdropBlur={"10px"} />

        <ModalContent>
          <ModalBody bg={allData.colorBody}>
            <VStack>
              <Box
                fontSize={20}
                textAlign="left"
                w="100%"
                fontWeight={600}
                py={2}
              >
                Discard all changes?
              </Box>
              <Box fontSize={16} textAlign="left" w="100%" fontWeight={400}>
                We won't be able to save your data if you move away from this
                page.
              </Box>
              <Spacer />
              <HStack w="100%">
                <Spacer />
                <Box>
                  <Button
                    tabIndex={2}
                    onClick={allData.onCloseConfirm} //onCloseConfirm
                  >
                    Go back
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      allData.reset();
                      allData.onCloseConfirm();
                    }}
                    colorScheme="danger"
                    bg="danger.500"
                    tabIndex={1}
                    autoFocus
                  >
                    Exit
                  </Button>
                </Box>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
