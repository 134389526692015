import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { HomeComponent } from "../Home/HomeComponent";
import { LogInComponent } from "../Login/LoginComponent";

import { ProjectsComponent } from "../ProjectsComponent/ProjectsComponent";

import { UserContext } from "../../App";
import { AllProjectsWrapper } from "../AllProjects/AllProjectsWrapper";

import { DashboardWrapper } from "../Dashboard/DashboardWrapper/DashboardWrapper";
import { NotFoundPage } from "../NotFoundPage/NotFoundPage";
import { ServerLogsPage } from "../ServerLogsPage";
import { UsersComponent } from "../UsersComponent/UsersComponent";
import { ProtectedRoute } from "./ProtectedRoute";

import { AboutPage } from "../AboutPage";
import { ClientsPage } from "../ClientsPage";
import { PlayGround } from "../Common/Playground";
import { HelpCenter } from "../HelpCenter";
import { ProjectDetails } from "../ProjectDetailsPage/ProjectDetails";
import { TaskLogsPage } from "../TaskLogsPage";
import { WhatsNewPage } from "../WhatsNewPage";

import { Flex } from "@chakra-ui/react";
import { TopicCardsMenu } from "../HelpCenter/TopicCardsMenu";
import { TopicPage } from "../HelpCenter/TopicPage";
import { BackupLogs } from "../Campaigns";
import { ThresholdTemplates } from "../ThresholdTemplates";
import { ResourcePageDetails } from "../ResourcePage/ResourceDetails";
import { ResourcePageOverview } from "../ResourcePage/ResourceOverview";
import { CampaignsExecutions } from "../Campaigns/Executions";
export const RoutesComponent: React.FC = () => {
  const userContext = useContext(UserContext);

  // eslint-disable-next-line

  return (
    <Routes>
      <Route path="/" element={<HomeComponent />} />
      <Route path="/login" element={<LogInComponent />} />
      {userContext?.user?.roleName === "Admin" && (
        <Route
          path="/dashboard/developers"
          element={
            <ProtectedRoute>
              <DashboardWrapper>
                <UsersComponent />
              </DashboardWrapper>
            </ProtectedRoute>
          }
        />
      )}
      {userContext?.user?.roleName === "Admin" && (
        <Route
          path="/dashboard/clients"
          element={
            <ProtectedRoute>
              <DashboardWrapper>
                <ClientsPage />
              </DashboardWrapper>
            </ProtectedRoute>
          }
        />
      )}
      <Route
        path="/dashboard/your-projects"
        element={
          <ProtectedRoute>
            <DashboardWrapper>
              <ProjectsComponent />
            </DashboardWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/about"
        element={
          <ProtectedRoute>
            <DashboardWrapper>
              <AboutPage />
            </DashboardWrapper>
          </ProtectedRoute>
        }
      />

      {userContext?.user?.roleName === "Admin" && (
        <Route
          path="/dashboard/all-projects"
          element={
            <ProtectedRoute>
              <DashboardWrapper>
                <AllProjectsWrapper />
              </DashboardWrapper>
            </ProtectedRoute>
          }
        />
      )}
      {userContext?.user?.roleName === "Admin" && (
        <Route
          path="/dashboard/server-templates"
          element={
            <ProtectedRoute>
              <DashboardWrapper>
                <ThresholdTemplates />
              </DashboardWrapper>
            </ProtectedRoute>
          }
        />
      )}
      <Route
        path="/dashboard/help/:helpTopic"
        element={
          <ProtectedRoute>
            <DashboardWrapper>
              <HelpCenter>
                <TopicPage />
              </HelpCenter>
            </DashboardWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/help"
        element={
          <ProtectedRoute>
            <DashboardWrapper>
              <HelpCenter>
                <TopicCardsMenu />
              </HelpCenter>
            </DashboardWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/projects/:projectId/:serverId"
        element={
          <ProtectedRoute>
            <DashboardWrapper>
              <ProjectDetails />
            </DashboardWrapper>
          </ProtectedRoute>
        }
      />

      <Route
        path="/dashboard/:projectId/:serverId/logs"
        element={
          <ProtectedRoute>
            <DashboardWrapper>
              <ServerLogsPage />
            </DashboardWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/:projectId/:serverId/:taskId/logs/:tabName"
        element={
          <ProtectedRoute>
            <DashboardWrapper>
              <TaskLogsPage />
            </DashboardWrapper>
          </ProtectedRoute>
        }
      />

      <Route path="/playground" element={<PlayGround />} />

      <Route
        path="/dashboard/whats-new"
        element={
          <ProtectedRoute>
            <DashboardWrapper>
              <WhatsNewPage />
            </DashboardWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/projects/:projectId/resources/:serverId"
        element={
          <DashboardWrapper>
            <ResourcePageOverview />
          </DashboardWrapper>
        }
      ></Route>
      <Route
        path="/dashboard/projects/:projectId/resources/:serverId/:graphOverviewType"
        element={
          <DashboardWrapper>
            <ResourcePageDetails />
          </DashboardWrapper>
        }
      ></Route>
      <Route
        path="/dashboard/campaigns"
        element={
          <ProtectedRoute>
            <DashboardWrapper>
              <CampaignsExecutions />
            </DashboardWrapper>
          </ProtectedRoute>
        }
      ></Route>
      {/* <Route
        path="/playground"
        element={
          <DashboardWrapper>
            <div>
              <TimeIntervalWrapper
                onIntervalChange={(val) => console.log(val)}
                title="CPU Overview"
                seeFullDetailsUrl="assda"
              ></TimeIntervalWrapper>
            </div>
          </DashboardWrapper>
        }
      />
      <Route
        path="/dashboard/backupCampaigns"
        element={
          <DashboardWrapper>
            <BackupLogs />
          </DashboardWrapper>
        }
      />

      {/* <Route
        path="/memory-chart"
        element={
          <DashboardWrapper>
            <ProcessChartSection />
          </DashboardWrapper>
        }
      /> */}

      <Route
        path="/dashboard/campaign-templates"
        element={
          <DashboardWrapper>
            <BackupLogs />
          </DashboardWrapper>
        }
      />

      <Route
        path="/dashboard/projects/:projectId/resources-overview"
        element={
          <DashboardWrapper>
            {
              <Flex justifyContent="center" fontSize={"3xl"}>
                Resources page
              </Flex>
            }
          </DashboardWrapper>
        }
      ></Route>

      {userContext?.user ? (
        <Route
          path="*"
          element={
            <DashboardWrapper>
              <NotFoundPage />{" "}
            </DashboardWrapper>
          }
        />
      ) : (
        <Route path="*" element={<NotFoundPage />} />
      )}
    </Routes>
  );
};
