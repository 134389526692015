import { Box, Flex, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  requiredField,
  validatorNumericField,
} from "../../Utils/ValidatorsForInput";
import {
  ProjectInterface,
  SelectOptionsInterface,
  ServerInterface,
} from "../Common/types";
import { WDDropdown } from "../Common/WDDropdown";
import { WDInput } from "../Common/WDInput";
import { apiClient, authorise } from "../utils/apiClient";
import { optionsTypesService } from "./defaultObjects";
import { DynamicFields } from "./DynamicFields";

import { ModalServiceInterface, ServiceErrorInterface } from "./type";

interface FirstStepProps {
  project: ProjectInterface;
  server: ServerInterface;
  values: ModalServiceInterface;
  setValues: (val: ModalServiceInterface) => void;
  errors: ServiceErrorInterface;
  setErrors: (val: ServiceErrorInterface) => void;
  mode?: "create" | "edit";
}

export const FirstStep: React.FC<FirstStepProps> = ({
  project,
  server,
  values,
  setValues,
  errors,
  setErrors,
  mode,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [typesOpetions, setTypesOptions] = useState<SelectOptionsInterface[]>(
    []
  );
  const [newFieldsLoading, setNewFieldsLoading] = useState<boolean>(false);

  useEffect(() => {
    apiClient
      .get("/api/service-check-attributes/all", authorise())
      .then((res) => {
        setTypesOptions(
          res.data.map((op: any) => {
            return {
              value: op.id,
              display: op.name,
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getDynamicFields = (serviceTypeId: string) => {
    setNewFieldsLoading(true);
    apiClient
      .get(`/api/service-check-attributes/fields/${serviceTypeId}`, authorise())
      .then((res) => {
        setValues({
          ...values,
          serviceTypeField: res.data.map((field: any) => {
            return {
              ...field,
              value: field.type === 30 ? "false" : "",
            };
          }),
          type: {
            id: serviceTypeId,
            name: typesOpetions.filter((o) => {
              return o.value === serviceTypeId;
            })[0]?.display,
          },
        });
        setErrors({
          ...errors,
          type: "",
          serviceTypeField: res.data.map((field: any) => {
            return {
              name: field.name,
              message: "",
            };
          }),
        });
        setNewFieldsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setNewFieldsLoading(false);
      });
  };

  return (
    <VStack w="100%">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <HStack w="100%" py={3}>
            <Box w="100%">
              <Text> Project's Name</Text>
              <WDInput
                isDisabled={true}
                placeholder={project.name}
                _disabled={{ bg: "#F8FAFC", color: "blue.800" }}
                border="1px solid"
                borderColor="neutralGrey"
              />
            </Box>

            <Box w="100%">
              <Text> Server's Name</Text>
              <WDInput
                placeholder={server.name}
                isDisabled={true}
                _disabled={{ bg: "#F8FAFC", color: "blue.800" }}
                border="1px solid"
                borderColor="neutralGrey"
              />
            </Box>
          </HStack>
          <Box w="100%">
            <Flex>
              <Text>Service's name</Text>
              <Text color="danger.500">*</Text>
            </Flex>
            <WDInput
              tabIndex={1}
              placeholder="Service's name"
              value={values.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValues({
                  ...values,
                  name: e.target.value,
                });
                setErrors({
                  ...errors,
                  name: requiredField(e.target.value),
                });
              }}
              error={errors.name}
            />
          </Box>{" "}
          <HStack w="100%" py={3} h="100%">
            <Box w="100%">
              <Flex>
                <Text>IPv4 Address </Text>
                <Text color="danger.500">*</Text>
              </Flex>
              <WDInput
                tabIndex={2}
                placeholder="IP Adress"
                value={values.ipV4}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValues({
                    ...values,
                    ipV4: e.target.value,
                  });
                  setErrors({
                    ...errors,
                    ipV4: requiredField(e.target.value),
                  });
                }}
                error={errors.ipV4}
              />
            </Box>
            <Box w="100%">
              <Text>IPv6 Adress </Text>
              <WDInput
                tabIndex={2}
                placeholder="IP Adress"
                value={values.ipV6}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValues({
                    ...values,
                    ipV6: e.target.value,
                  });
                }}
              />
            </Box>
          </HStack>
          <HStack w="100%" py={3} h="100%">
            <Box w="100%">
              <Flex>
                <Text> Port Number </Text>
                <Text color="danger.500">*</Text>
              </Flex>
              <WDInput
                tabIndex={3}
                placeholder="Port number"
                value={values.port}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (validatorNumericField(e.target.value) === "") {
                    setValues({
                      ...values,
                      port: e.target.value,
                    });

                    setErrors({
                      ...errors,
                      port:
                        e.target.value === ""
                          ? requiredField(e.target.value)
                          : "",
                    });
                  }
                }}
                error={errors.port}
              />
            </Box>
            <Box w="100%" h="100%">
              <Flex>
                <Text>Type </Text>
                <Text color="danger.500">*</Text>
              </Flex>
              <WDDropdown
                isDisabled={mode === "edit"}
                value={values.type.id}
                options={
                  mode === "edit"
                    ? optionsTypesService
                    : typesOpetions.sort((o1, o2) => {
                        if (o1.display.toLowerCase() === "genericservice") {
                          return -1;
                        }

                        if (o2.display.toLowerCase() === "genericservice") {
                          return 1;
                        }
                        if (
                          o1.display.toLowerCase() < o2.display.toLowerCase()
                        ) {
                          return -1;
                        }
                        if (
                          o1.display.toLowerCase() > o2.display.toLowerCase()
                        ) {
                          return 1;
                        }
                        return 0;
                      })
                }
                onChange={(op) => {
                  getDynamicFields(op);
                }}
                placeholder="Select type"
                error={errors.type}
              />
            </Box>
          </HStack>
          <HStack width="100%">
            {newFieldsLoading ? (
              <Spinner />
            ) : (
              <DynamicFields
                values={values}
                setValues={setValues}
                errors={errors}
                setErrors={setErrors}
              />
            )}
          </HStack>
        </>
      )}
    </VStack>
  );
};
