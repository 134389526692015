import { Flex, Switch, Text } from "@chakra-ui/react";
import React from "react";
import { booleanConverter, cammelCaseToNormal } from "../../../Utils/formaters";
import { ModalServiceInterface, serviceTypeFieldInterface } from "../type";

interface BooleanFieldProps {
  field: serviceTypeFieldInterface;
  values: ModalServiceInterface;
  setValues: (val: ModalServiceInterface) => void;
}

export const BooleanField: React.FC<BooleanFieldProps> = ({
  field,
  values,
  setValues,
}) => {
  return (
    <Flex width="100%" key={field.name} alignItems="center">
      <Flex
        width="100%"
        alignItems="flex-start"
        justify="center"
        direction="column"
      >
        <Text>{cammelCaseToNormal(field.name)}</Text>

        <Switch
          size="md"
          isChecked={booleanConverter(
            values.serviceTypeField.filter((f) => {
              return f.name === field.name;
            })[0]?.value
          )}
          onChange={() => {
            setValues({
              ...values,
              serviceTypeField: values.serviceTypeField.map((f) => {
                if (f.name === field.name) {
                  return {
                    ...f,
                    value: !values.serviceTypeField.filter((f) => {
                      return f.name === field.name;
                    })[0]?.value,
                  };
                } else {
                  return f;
                }
              }),
            });
          }}
          _focus={{
            boxShadow: "none",
          }}
        />
      </Flex>
    </Flex>
  );
};
