import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

import { cammelCaseToNormal } from "../../../Utils/formaters";
import { validatorNumericField } from "../../../Utils/ValidatorsForInput";
import { WDInput } from "../../Common/WDInput";
import {
  ModalServiceInterface,
  ServiceErrorInterface,
  serviceTypeFieldInterface,
} from "../type";

interface NumericFieldProps {
  field: serviceTypeFieldInterface;
  errors: ServiceErrorInterface;
  setErrors: (val: ServiceErrorInterface) => void;
  values: ModalServiceInterface;
  setValues: (val: ModalServiceInterface) => void;
}

export const NumericField: React.FC<NumericFieldProps> = ({
  field,
  errors,
  setErrors,
  values,
  setValues,
}) => {
  return (
    <Box w="100%" key={field.name}>
      <Flex>
        <Text> {cammelCaseToNormal(field.name)} </Text>
        <Text color="danger.500">*</Text>
      </Flex>
      <WDInput
        tabIndex={3}
        placeholder={cammelCaseToNormal(field.name)}
        value={values.serviceTypeField
          .filter((f) => {
            return f.name === field.name;
          })[0]
          ?.value?.toString()}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (validatorNumericField(e.target.value) === "") {
            setValues({
              ...values,
              serviceTypeField: values.serviceTypeField.map((f) => {
                if (f.name === field.name) {
                  return {
                    ...f,
                    value: e.target.value === "" ? 0 : parseInt(e.target.value),
                  };
                } else {
                  return f;
                }
              }),
            });

            setErrors({
              ...errors,
              serviceTypeField: errors.serviceTypeField.map((err) => {
                if (err.name === field.name) {
                  return {
                    ...err,
                    message: "",
                  };
                } else {
                  return err;
                }
              }),
            });
          }
        }}
        error={
          errors.serviceTypeField.filter((err) => {
            return err.name === field.name;
          })[0]?.message
        }
      />
    </Box>
  );
};
