import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorModeValue,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MotionButton } from "../../../Utils/MotionComponents";
import { ThresholdTemplateInterface } from "../../ThresholdTemplates/types";
import { apiClient, authorise } from "../../utils/apiClient";
import { ThresholdsInterface } from "./types";

interface ChooseTemplateModalProps {
  isOpenChooseTemplate: boolean;
  setIsOpenChooseTemplate: (val: boolean) => void;
  setIsOpenThresholds: (val: boolean) => void;
  isRendered: boolean;
  onClickTemplate: (val: ThresholdsInterface) => void;
  onClickNoTemplate: (val: void) => void;
}

export const ChooseTemplateModal: React.FC<ChooseTemplateModalProps> = ({
  isOpenChooseTemplate,
  setIsOpenChooseTemplate,
  setIsOpenThresholds,
  isRendered,
  onClickTemplate,
  onClickNoTemplate,
}) => {
  const colorSecondary = useColorModeValue("blue.800", "darkThemeGrey.100");
  const colorPrimary = useColorModeValue("blue.700", "blue.400");
  const bgColor = useColorModeValue("white", "darkThemeGrey.700");

  const [loading, setLoading] = useState<boolean>(true);
  const [templates, setTemplates] = useState<ThresholdTemplateInterface[]>([]);
  const [stopIt, setStopIt] = useState<boolean>(false);

  if (isRendered && !stopIt) {
    apiClient
      .get("/api/template/all", authorise())
      .then((res) => {
        setStopIt(true);
        if (res.data.length) {
          setTemplates(res.data);
          if (!res.data.length) {
            onClickNoTemplate();
          }
          setLoading(false);
        } else {
          setIsOpenThresholds(true);
          setIsOpenChooseTemplate(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Modal
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
      size={"3xl"}
      isOpen={isOpenChooseTemplate}
      onClose={() => {
        setIsOpenChooseTemplate(false);
      }}
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalHeader
          borderRadius={"8px 8px 0px 0px"}
          backgroundColor={useColorModeValue("blue.100", "darkThemeGrey.600")}
          color={colorSecondary}
        >
          Choose Template
        </ModalHeader>
        <ModalCloseButton
          margin={"5px 8px 0px 0px"}
          borderWidth={2}
          borderRadius={5}
          color={colorSecondary}
          borderColor={colorSecondary}
        />
        {loading ? (
          <Flex h={300} align="center" justify="center">
            <Spinner />
          </Flex>
        ) : (
          <ModalBody padding={5} bg={bgColor} minH={400}>
            <Flex
              w="100%"
              align="center"
              justify="flex-start"
              wrap="wrap"
              columnGap={5}
              rowGap={5}
            >
              {templates?.map((template) => {
                return (
                  <Box
                    cursor="pointer"
                    key={template.id}
                    border="1px solid"
                    borderColor="neutralDarkGrey"
                    borderRadius={10}
                    p={2}
                    w="30%"
                    minW="fit-content"
                    textAlign="center"
                    onClick={() => {
                      onClickTemplate(template.value);
                    }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight={600}
                      color="neutralDarkGrey"
                    >
                      {template.name}
                    </Text>
                  </Box>
                );
              })}
            </Flex>
          </ModalBody>
        )}
        <ModalFooter bg={useColorModeValue("white", "darkThemeGrey.700")}>
          <MotionButton
            tabIndex={1}
            backgroundColor={colorPrimary}
            colorScheme={"blue"}
            color={"white"}
            fontSize={"sm"}
            minW="fit-content"
            width={"30%"}
            mr={3}
            onClick={onClickNoTemplate}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", bounce: 0.4 }}
          >
            Continue without template
          </MotionButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
