import {
  ComponentWithAs,
  Flex,
  IconProps,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../App";
import { theme } from "../../../theme";
interface DrawerLinkElementProps {
  Icon:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    | ComponentWithAs<"svg", IconProps>;
  url: string;
  isAdmin: boolean;
}
export const DrawerLinkElement: React.FC<DrawerLinkElementProps> = ({
  Icon,
  url,
  isAdmin,
}) => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const handleNavigate = (name: string) => {
    if (localStorage.accesToken) {
      navigate("/dashboard/" + name);
    } else {
      userContext?.logOut();
    }
  };
  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.darkThemeGrey[100]}`
  );
  const colorBorder = useColorModeValue("blue.700", "blue.400");
  return (
    <>
      {isAdmin ? (
        <Flex
          __css={{
            direction: "ltr",
          }}
          p={2}
          width="100%"
          h="100%"
          borderRight={location.pathname.includes(url) ? "10px solid" : "0"}
          borderColor={colorBorder}
          onClick={() => {
            handleNavigate(url);
          }}
          cursor="pointer"
        >
          <Icon
            as={Icon}
            stroke={colorStroke}
            opacity={location.pathname.includes(url) ? "100%" : "50%"}
            fontFamily="Montserrat"
          />
        </Flex>
      ) : null}
    </>
  );
};
